import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PriceBookClient from '../../api/PriceBook/PricebookAPI';
import OrgHierarchyClient from '../../api/UserOrg/UserOrg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import Snackbar from '../../components/Snackbar/Snackbar';
import { ISnackBar } from '../../models/common';
import { setPriceBooks } from '../../store/price_book/pricebook.slice';
import commonStyle from '../../styles/commonStyle';
import { dropdownComponent } from '../PricingCalculator/SelectionSection';
import TenantsClient from '../../api/Tenant/TenantAPIs';
import { setDealTerms } from '../../store/deal_terms/dealTerms.Slice';
import ConfirmDialog from '../../components/DialogBox/ConfirmDialog';
import styles from '../../styles/styles';

const componentStyle = {
  sectionName: {
    color: '#3B3F4D',
    marginBottom: '14px',
    fontFamily: 'Helvetica',
    fontWeight: '700',
  },
  tableText: {
    width: '250px',
    height: '35px',
    color: 'white',
    backgroundColor: '#4168E1',
    display: 'block',
    padding: '10px',
  },
  btnStyle: {
    background: '#5D5FEF',
    ':hover': { backgroundColor: '#5D5FEF' },
    '&:disabled': {
      color: 'white',
      opacity: 0.5,
    },
    color: 'white',
    fontFamily: 'Helvetica',
    fontWeight: '700',
    borderRadius: '10px',
    padding: '10px 20px',
  },
  btnContainer: {
    textAlign: 'right',
    marginRight: '18px',
  },
  paperContainer: {
    minHeight: '120px',
    display: 'flex',
    padding: '18px 33px',
    gap: '18px',
  },
  paperContainerDiscount: {
    minHeight: '60px',
    display: 'flex',
    padding: '18px 33px',
    gap: '18px',
    backgroundColor: '#E5E5E575',
  },
  paperContainerHeading: {
    display: 'flex',
    padding: '18px 33px',
    gap: '18px',
    backgroundColor: '#E5E5E575',
    boxShadow: 'none',
  },
  paperBottomContainer: {
    padding: '18px 33px',
    backgroundColor: '#E5E5E575',
    boxShadow: 'none',
  },
  deleteIcon: {
    display: 'block',
    marginTop: '14px',
    minWidth: '30px',
    textAlign: 'center',
    ':hover': { cursor: 'pointer' },
  },
  sectionBox: {
    display: 'inline-block',
    width: '300px',
    verticalAlign: 'top',
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    marginY: '20px',
    fontFamily: 'Helvetica',
    fontWeight: '700',
    // backgroundColor: '#E5E5E575',
  },
};

const DiscountingPolicy: React.FC<any> = ({ intl }) => {
  const dispatch = useDispatch();
  const priceBooks = useSelector((state: any) => state.priceBook.priceBooks);
  const dealTerms = useSelector((state: any) => state.dealTerms.dealTerms);

  const [selectedPricingBook, setSelectedPricingBook] = useState<any>();
  const [productRows, setProductRows] = useState<any>([]);
  const [hierarchyList, setHierarchyList] = useState<any>([]);
  const [sortedHierarchyList, setSortedHierarchyList] = useState<any>([]);
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [filteredDealTerms, setFilteredDealTerms] = useState<any>([]);
  const [dealTermDiscountFromApi, setDealTermDiscountFromApi] = useState<any>([]);
  const [hierarchyDiscountFromApi, setHierarchyDiscountFromApi] = useState<any>([]);
  const [deleteHierarchyIndex, setDeleteHierarchyIndex] = useState(0);
  const [editDealTermDiscountIndex, setEditDealTermDiscountIndex] = useState(0);
  const [dealTermDiscountValues, setDealTermDiscountValues] = useState<any>([]);
  const [finalHierarchyObj, setFinalHierarchyObj] = useState<any>({});
  const [hierarchyData, setHierarchyData] = useState<any>(finalHierarchyObj);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteProdId, setDeleteProdId] = useState('');
  const [deleteProdName, setDeleteProdName] = useState('');
  const [disabledArr, setDisabledArr] = useState<number[]>([]);
  const [dealTermsLoader, setDealTermsLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [IsdealTermLoader, setIsDealTermLoader] = useState(false);
  const [pricingBookLoader, setPricingBookLoader] = useState(false);
  const [editHierarchyLoader, setEditHierarchyLoader] = useState(false);
  const [createHierarchyLoader, setCreateHierarchyLoader] = useState(false);
  const [deleteHierarchyLoader, setDeleteHierarchyLoader] = useState(false);
  const [isHierarchyListLoading, setIsHierarchyLoading] = useState(false);
  const [isGetDiscountPolicyLoading, setIsGetDiscountPolicyLoading] = useState(false);
  const [addNewHierarchyDesignation, setAddNewHierarchyDesignation] = useState<any>([]);
  const [isErrorDealTerm, setIsErrorDealTerm] = useState(false);
  const [isGetDiscountPolicyError, setIsGetDiscountPolicyError] = useState(false);
  const [isDiscountPercentLimitError, setIsDiscountPercentLimitError] = useState(false);
  const [isDiscountPercentLimitErrorEdit, setIsDiscountPercentLimitErrorEdit] = useState(false);
  const [isEditHierarchyError, setIsEditHierarchyError] = useState(false);
  const [isCreateHierarchyError, setIsCreateHierarchyError] = useState(false);
  const [addEmptyHirerachyRow, setAddEmptyHirerachyRow] = useState<any>([]);
  const [dealTermDiscount, setDealTermDiscount] = useState<any>([
    { input1: '', input2: '', input3: '' },
  ]);
  const [editedDealTerm, setEditedDealTerm] = useState<any>({});
  useEffect(() => {
    if (productRows.length > 0 && Object.keys(finalHierarchyObj).length > 0) {
      const apiData: any = { ...finalHierarchyObj };
      Object.keys(apiData).forEach((orgId) => {
        const hierarchyListss = apiData[orgId][orgId];
        const filteredHierarchyListss = hierarchyListss.filter((i: any) => i !== undefined);
        const reorderedHierarchy = productRows.map((productRow: any) =>
          filteredHierarchyListss.find((item: any) => item.product_id === productRow.id),
        );

        apiData[orgId][orgId] = reorderedHierarchy;
      });

      setHierarchyData(apiData);
    } else {
      setHierarchyData({});
    }
  }, [finalHierarchyObj, productRows]);
  useEffect(() => {
    if (dealTermDiscountFromApi.length > 0 && filteredDealTerms.length > 0) {
      const enabledList = dealTermDiscountFromApi.filter((discount: any) =>
        filteredDealTerms.some(
          (term: any) =>
            term.id === discount.deal_term_id &&
            (term.component !== 'checkbox'
              ? term.options.some(
                  (option: any) =>
                    option.id === discount.deal_term_option_id && option.is_active === true,
                )
              : true),
        ),
      );
      setDealTermDiscountValues(
        enabledList.map((item: any) => ({
          dealTermId: item.deal_term_id,
          discount: +item.discount,
          dealTermOptionId: item.deal_term_option_id,
          id: item.id,
          value: item.value,
          component: item.deal_term_option_id === null ? 'checkbox' : 'other',
        })),
      );

      setDealTermDiscount([]);
    } else {
      setDealTermDiscountValues([]);
      setDealTermDiscount([{ input1: '', input2: '', input3: '' }]);
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const indexArray = Array.from({ length: dealTermDiscountFromApi?.length }, (_, index) => index);
    setDisabledArr(indexArray);
  }, [dealTermDiscountFromApi, filteredDealTerms]);
  useEffect(() => {
    const indexArray = Array.from({ length: dealTermDiscountValues?.length }, (_, index) => index);
    setDisabledArr(indexArray);
  }, [dealTermDiscountValues.length]);

  useEffect(() => {
    getHierarchy();
    getPriceBooks();
    getDealTerms();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dealTerms.length > 0 && !dealTermsLoader) {
      const filteredDealTerm = dealTerms.filter(
        (item: any) =>
          (item.component === 'radio' ||
            item.component === 'select' ||
            item.component === 'checkbox') &&
          item.is_enabled === true,
      );
      setFilteredDealTerms(filteredDealTerm);
    }
  }, [dealTerms, dealTermsLoader]);
  useEffect(() => {
    if (selectedPricingBook && selectedPricingBook.id) {
      setProductRows(
        selectedPricingBook?.products?.map((p: any) => {
          return { product: p.product_name, id: p.product_id };
        }),
      );
      getDiscountPolicy();
    } else {
      setProductRows([]);
    }
    // eslint-disable-next-line
  }, [selectedPricingBook]);

  useEffect(() => {
    if (Object.keys(hierarchyDiscountFromApi).length > 0) {
      const result: any = {};

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in hierarchyDiscountFromApi) {
        const { level, ...rest } = hierarchyDiscountFromApi[key];
        result[key] = rest;
      }

      setFinalHierarchyObj(result);
    } else {
      setFinalHierarchyObj({});
    }
  }, [hierarchyDiscountFromApi]);

  useEffect(() => {
    if (
      selectedPricingBook &&
      Object.keys(selectedPricingBook).length > 0 &&
      productRows.length > 0 &&
      addEmptyHirerachyRow.length === 0 &&
      Object.keys(hierarchyData).length === 0
    ) {
      addEmptyRow();
    } else {
      setAddEmptyHirerachyRow([]);
      setAddNewHierarchyDesignation([]);
    }
  }, [selectedPricingBook, productRows, hierarchyData]);
  useEffect(() => {
    if (hierarchyList.length > 0 && Object.keys(hierarchyData).length > 0) {
      const val = Object.keys(hierarchyData).slice(-1)[0];
      const data = hierarchyList.find((item: any) => item.id === val);
      if (data) {
        const reqLevel: number = data.level;
        const newHierarchyList = hierarchyList.filter((item: any) => item.level < reqLevel);
        setSortedHierarchyList(newHierarchyList);
      }
    } else if (hierarchyList.length > 0 && Object.keys(hierarchyData).length === 0) {
      setSortedHierarchyList(hierarchyList);
    }
  }, [hierarchyList, hierarchyData]);
  useEffect(() => {
    if (dealTermDiscount.length === 0) {
      setIsErrorDealTerm(false);
      setIsDiscountPercentLimitError(false);
    }
  }, [dealTermDiscount]);

  const getPriceBooks = () => {
    setPricingBookLoader(true);
    PriceBookClient.getPriceBooks({})
      .then((response: any) => {
        dispatch(setPriceBooks(response.data));
        if (response.data.length === 1) setSelectedPricingBook(response.data[0]);
        setPricingBookLoader(false);
      })
      .catch(() => {
        setPricingBookLoader(false);
      });
  };

  const getDealTerms = () => {
    setDealTermsLoader(true);
    TenantsClient.getDealTerms({})
      .then((response: any) => {
        if (response.message === 'success') {
          dispatch(setDealTerms(response.data));
        }
        setDealTermsLoader(false);
      })
      .catch(() => {
        setDealTermsLoader(false);
      });
  };

  const getHierarchy = () => {
    setIsHierarchyLoading(true);
    OrgHierarchyClient.getOrgHierarchyStructure()
      .then((response: any) => {
        setHierarchyList(response.data);
        setIsHierarchyLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
        setIsHierarchyLoading(false);
      });
  };

  const getDiscountPolicy = async () => {
    try {
      setIsGetDiscountPolicyLoading(true);
      const response = await PriceBookClient.getDiscountPolicy(selectedPricingBook.id);
      if (response.message === 'success') {
        if (response.data.deal_term_based_discount) {
          setDealTermDiscountFromApi(response.data.deal_term_based_discount);
        } else {
          setDealTermDiscountFromApi([]);
        }
        if (response.data.hierarchy_based_discount.length > 0) {
          const a = response.data.hierarchy_based_discount;
          const merged: any = {};

          a.forEach((item: any) => {
            const key = Object.keys(item).find((k) => k !== 'level');
            if (key !== undefined) {
              if (!merged[key]) {
                merged[key] = { ...item, [key]: [...item[key]] };
              } else {
                merged[key][key] = merged[key][key].concat(item[key]);
              }
            }
          });
          setHierarchyDiscountFromApi(merged);
        } else {
          setHierarchyDiscountFromApi([]);
        }
        setIsGetDiscountPolicyError(false);
        setIsGetDiscountPolicyLoading(false);
        setIsEditHierarchyError(false);
        setIsCreateHierarchyError(false);
        setDealTermDiscount([]);
        setIsDiscountPercentLimitError(false);
        setIsErrorDealTerm(false);
      }
    } catch (error) {
      console.log(error, 'getDiscountPolicy get api failed');
      setIsGetDiscountPolicyError(false);
      setIsGetDiscountPolicyLoading(false);
    }
  };

  const addEmptyRow = () => {
    const final: any = [...addEmptyHirerachyRow];
    const newHierarchyRow: any = [...productRows];

    const updatedFinal = newHierarchyRow.map((row: any) => ({ ...row, discount: '' }));
    final.push(updatedFinal);

    setAddEmptyHirerachyRow(final);

    const newDesignation = [...addNewHierarchyDesignation];
    newDesignation.push({ designation: '' });
    setAddNewHierarchyDesignation(newDesignation);
  };

  const handleAddNewDiscount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    outerIndex: number,
    itemm: any,
    innerIndex: number,
  ) => {
    const { name, value } = e.target;
    let newAddEmptyHirerachyRow;
    if (name === 'discount') {
      newAddEmptyHirerachyRow = addEmptyHirerachyRow.map((innerArray: any, index: number) => {
        if (index === outerIndex) {
          return innerArray.map((item: any, idx: number) => {
            if (idx === innerIndex) {
              return { ...item, discount: value };
            }
            return item;
          });
        }
        return innerArray;
      });
    }
    setAddEmptyHirerachyRow(newAddEmptyHirerachyRow);
  };
  const handleDeleteNewColumn = (index: number) => {
    const arr: any = [...addEmptyHirerachyRow];
    arr.splice(index, 1);
    setAddEmptyHirerachyRow(arr);
    const removeDesignation: any = [...addNewHierarchyDesignation];
    removeDesignation.splice(index, 1);
    setAddNewHierarchyDesignation(removeDesignation);
  };

  const handleSelectChange = (
    org_hierarchy_id: string,
    product_id: string,
    event: SelectChangeEvent<unknown>,
  ) => {
    const newHierarchyData = { ...hierarchyData };
    const newOrgHierarchyId = event.target.value as string;
    const updatedItems = newHierarchyData[org_hierarchy_id][org_hierarchy_id].map((item: any) =>
      item.product_id === product_id ? { ...item, org_hierarchy_id: newOrgHierarchyId } : item,
    );
    newHierarchyData[newOrgHierarchyId] = {
      [newOrgHierarchyId]: updatedItems,
    };
    delete newHierarchyData[org_hierarchy_id];

    // console.log(updatedItems, 'updatedItems');
    // newHierarchyData[newOrgHierarchyId][newOrgHierarchyId] = updatedItems;
    // const filteredHierarchyData = Object.fromEntries(
    //   Object.entries(newHierarchyData).filter(([key]) => key !== org_hierarchy_id),
    // );
    setHierarchyData(newHierarchyData);
  };
  // console.log(hierarchyData, 'hhh');
  const handleTextFieldChange = (
    org_hierarchy_id: string,
    product_id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newHierarchyData = { ...hierarchyData };
    const updatedItems = newHierarchyData[org_hierarchy_id][org_hierarchy_id].map((item: any) =>
      item.product_id === product_id ? { ...item, discount: +event.target.value } : item,
    );
    newHierarchyData[org_hierarchy_id][org_hierarchy_id] = updatedItems;
    setHierarchyData(newHierarchyData);
  };

  const handleDiscountFormatHirerachy = (
    org_hierarchy_id: string,
    product_id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    const num = +value;
    if (name === 'discount') {
      let final: any;
      if (num % 1 !== 0) {
        final = num.toFixed(2);
      } else {
        final = num.toFixed(0);
      }
      const newHierarchyData = { ...hierarchyData };
      const updatedItems = newHierarchyData[org_hierarchy_id][org_hierarchy_id].map((item: any) =>
        item.product_id === product_id ? { ...item, discount: final } : item,
      );
      newHierarchyData[org_hierarchy_id][org_hierarchy_id] = updatedItems;
      setHierarchyData(newHierarchyData);
    }
  };

  const handleDesignation = (e: SelectChangeEvent<unknown>, index: number) => {
    const { value } = e.target;
    const duplicate: any = [...addNewHierarchyDesignation];
    duplicate[index].designation = value;

    setAddNewHierarchyDesignation(duplicate);
  };

  const hirerachyDiscountFormatting = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    outerIndex: number,
    itemm: any,
    innerIndex: number,
  ) => {
    const { name, value } = e.target;
    const num = +value;
    let newAddEmptyHirerachyRow;
    if (name === 'discount') {
      let final: any;
      if (num % 1 !== 0) {
        final = num.toFixed(2);
      } else {
        final = num.toFixed(0);
      }
      newAddEmptyHirerachyRow = addEmptyHirerachyRow.map((innerArray: any, index: number) => {
        if (index === outerIndex) {
          return innerArray.map((item: any, idx: number) => {
            if (idx === innerIndex) {
              return { ...item, discount: final };
            }
            return item;
          });
        }
        return innerArray;
      });
    }
    setAddEmptyHirerachyRow(newAddEmptyHirerachyRow);
  };

  const handleDeleteHierarchy = async (val: string, index: number) => {
    setDeleteHierarchyLoader(true);
    setDeleteHierarchyIndex(index);

    try {
      const response = await PriceBookClient.deleteHierarchy(selectedPricingBook.id, val);
      if (response.message === 'Hierarchy Discount Policies Deleted Successfully') {
        getDiscountPolicy();
        setSnackBarValues({
          display: true,
          type: 'success',
          message: intl.formatMessage({ id: 'deleteHierarchySuccess' }),
        });
        setAddEmptyHirerachyRow([]);
        setAddNewHierarchyDesignation([]);
      }
      setDeleteHierarchyLoader(false);
    } catch (err) {
      console.log(err, 'failed');
      setDeleteHierarchyLoader(false);
    }
  };

  const isValidEditHierarchyData = (result: any) => {
    let isError = false;

    result.forEach((obj: any) => {
      Object.keys(obj).forEach((key: string) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key] === false) {
          isError = true;
        }
        if (key === 'discount' && (obj[key] < 0 || obj[key] > 100)) {
          isError = true;
        }
      });
    });

    setIsEditHierarchyError(isError);
    return !isError;
  };

  const handleEditHierarchy = async (result: any) => {
    const updatedResult = addLevel(result);
    setEditHierarchyLoader(true);
    try {
      const response = await PriceBookClient.editHierarchy(selectedPricingBook.id, updatedResult);
      if (response.message === 'success') {
        getDiscountPolicy();
        setSnackBarValues({
          display: true,
          type: 'success',
          message: intl.formatMessage({ id: 'dataSaved' }),
        });
        setAddEmptyHirerachyRow([]);
        setAddNewHierarchyDesignation([]);
      }
      setEditHierarchyLoader(false);
    } catch (err) {
      console.log(err, 'failed');
      setEditHierarchyLoader(false);
    }
  };

  interface HierarchyData {
    org_hierarchy_id: string;
    discount: string;
  }

  const isValidCreateHierarchyData = (result: HierarchyData[]) => {
    return result.every((obj) => {
      const isValid = Object.values(obj).every((value, index) => {
        if (value === '' || value === undefined || value === null) {
          setIsCreateHierarchyError(true);
          return false;
        }

        if (Object.keys(obj)[index] === 'discount') {
          const discountValue = +value;
          if (Number.isNaN(discountValue) || discountValue < 0 || discountValue > 100) {
            setIsCreateHierarchyError(true);
            return false;
          }
        }

        return true;
      });

      if (isValid) {
        setIsCreateHierarchyError(false);
      }

      return isValid;
    });
  };

  const createHierarchy = async (result: any) => {
    const updatedResult = addLevel(result);
    setCreateHierarchyLoader(true);

    try {
      const response = await PriceBookClient.createHierarchy(selectedPricingBook.id, updatedResult);
      if (response.message === 'success') {
        getDiscountPolicy();
        setSnackBarValues({
          display: true,
          type: 'success',
          message: intl.formatMessage({ id: 'createHierarchySuccess' }),
        });
        setAddEmptyHirerachyRow([]);
        setAddNewHierarchyDesignation([]);
      }
      setCreateHierarchyLoader(false);
    } catch (err) {
      console.log(err, 'failed');
      setCreateHierarchyLoader(false);
    }
  };

  const addLevel = (result: any) => {
    const updatedResult = result.map((item: any) => {
      const hierarchy = hierarchyList.find((h: any) => h.id === item.org_hierarchy_id);
      return {
        ...item,
        level: hierarchy?.level ?? null,
      };
    });
    return updatedResult;
  };

  const hitBothcreateAndEditHierarchyApi = async (result: any, editApiData: any) => {
    setEditHierarchyLoader(true);
    const updatedCreateData = addLevel(result);
    const updatedEditData = addLevel(editApiData);
    try {
      const response = await PriceBookClient.editHierarchy(selectedPricingBook.id, updatedEditData);
      if (response.message === 'success') {
        createHierarchy(updatedCreateData);
      }
      setEditHierarchyLoader(false);
    } catch (err) {
      console.log(err, 'failed');
      setEditHierarchyLoader(false);
      setTimeout(() => {
        setAddEmptyHirerachyRow([]);
        getDiscountPolicy();
      }, 1000);
    }
  };

  const validateBothApiData = () => {
    const createApiDataSet = addEmptyHirerachyRow.map((subArray: any, index: number) => {
      return subArray.map((item: any) => {
        return {
          ...item,
          designation: addNewHierarchyDesignation[index].designation,
        };
      });
    });

    let editApiDataSet: any = [];
    Object.values(hierarchyData).forEach((innerObj: any) => {
      Object.values(innerObj).forEach((arr: any) => {
        editApiDataSet = editApiDataSet.concat(arr);
      });
    });

    if (createApiDataSet.flat().length === 0) {
      setIsCreateHierarchyError(false);
      if (editApiDataSet.length > 0) {
        if (isValidEditHierarchyData(editApiDataSet)) {
          handleEditHierarchy(editApiDataSet);
        }
      }
    } else {
      const createApiData = createApiDataSet.flat().map((item: any) => ({
        tenant_id: selectedPricingBook.tenant_id,
        price_book_id: selectedPricingBook.id,
        product_id: item.id,
        org_hierarchy_id: item.designation,
        discount: item.discount,
      }));

      if (
        isValidCreateHierarchyData(createApiData) &&
        editApiDataSet.length > 0 &&
        isValidEditHierarchyData(editApiDataSet)
      ) {
        hitBothcreateAndEditHierarchyApi(createApiData, editApiDataSet);
      } else if (
        (editApiDataSet.length === 0 || !isValidEditHierarchyData(editApiDataSet)) &&
        isValidCreateHierarchyData(createApiData)
      ) {
        createHierarchy(createApiData);
      }
    }
  };

  const addDealTermDiscountRow = () => {
    setDealTermDiscount([...dealTermDiscount, { input1: '', input2: '', input3: '' }]);
  };

  const getOptions = (val: string) => {
    const filtered = filteredDealTerms.find((item: any) => item.id === val);
    if (filtered?.component === 'checkbox') {
      return [
        { id: 'true', label: 'true' },
        { id: 'false', label: 'false' },
      ];
    }
    if (filtered && filtered.options.length > 0) {
      const finalOptionsArr: any = filtered.options.filter((i: any) => i.is_active === true);
      return finalOptionsArr;
    }
    return [];
  };
  const booleanArray = [
    { id: 'true', label: 'true' },
    { id: 'false', label: 'false' },
  ];

  const handleChange = (u: any, idx: number) => {
    const { name, value } = u.target;
    const newDealTermDiscount = [...dealTermDiscount];

    if (name === 'input1') {
      newDealTermDiscount[idx].input1 = value;
      newDealTermDiscount[idx].input2 = '';
      newDealTermDiscount[idx].input3 = '';
      setDealTermDiscount(newDealTermDiscount);
    } else {
      newDealTermDiscount[idx][name] = value;
      setDealTermDiscount(newDealTermDiscount);
    }
  };

  const handleRemoveDiscountRow = (val: number) => {
    const newDealTermDiscount = dealTermDiscount.filter((_item: any, idx: number) => idx !== val);
    setDealTermDiscount(newDealTermDiscount);
  };

  const DiscountToFixed = (u: any, idx: number) => {
    const { name, value } = u.target;

    const num = +value;
    if (name === 'input3') {
      const newDealTermDiscount = [...dealTermDiscount];
      let final;
      if (num % 1 !== 0) {
        final = num.toFixed(2);
      } else {
        final = num.toFixed(0);
      }
      newDealTermDiscount[idx].input3 = final;
      setDealTermDiscount(newDealTermDiscount);
    }
    if (name === 'discount') {
      const newDealTermDiscountValues = [...dealTermDiscountValues];
      let final;
      if (num % 1 !== 0) {
        final = num.toFixed(2);
      } else {
        final = +num.toFixed(0);
      }

      const strippedValue = final.toString().replace(/^0+/, '') || '0';
      newDealTermDiscountValues[idx] = {
        ...newDealTermDiscountValues[idx],
        discount: strippedValue,
      };
      setDealTermDiscountValues(newDealTermDiscountValues);
    }
  };

  const isValidDealTermBasedDiscount = () => {
    if (dealTermDiscount.length > 0) {
      for (let i = 0; i < dealTermDiscount.length; i += 1) {
        const discountRow = dealTermDiscount[i];
        if (discountRow.input1 === '' || discountRow.input2 === '' || discountRow.input3 === '') {
          setIsErrorDealTerm(true);
          setIsDiscountPercentLimitError(false);
          return false;
        }
        if (+discountRow.input3 < -50 || +discountRow.input3 > 100) {
          setIsDiscountPercentLimitError(true);
          setIsErrorDealTerm(false);
          return false;
        }
      }
    }
    setIsErrorDealTerm(false);
    setIsDiscountPercentLimitError(false);

    return true;
  };

  const saveDealTermBasedDiscount = async () => {
    if (isValidDealTermBasedDiscount()) {
      setIsDealTermLoader(true);
      const finalDiscountArray: any = [];
      for (let i = 0; i < dealTermDiscount.length; i += 1) {
        const discountRow = dealTermDiscount[i];
        const obj: any = {};
        obj.price_book_id = selectedPricingBook.id;
        obj.tenant_id = selectedPricingBook.tenant_id;
        obj.deal_term_id = discountRow.input1;
        obj.deal_term_option_id =
          discountRow.input2 === 'true' || discountRow.input2 === 'false' ? '' : discountRow.input2;
        obj.value =
          discountRow.input2 === 'true' || discountRow.input2 === 'false' ? discountRow.input2 : '';
        obj.discount = discountRow.input3;
        finalDiscountArray.push(obj);
      }

      const apiData = {
        pricebook_id: selectedPricingBook.id,
        data: finalDiscountArray,
      };
      try {
        const response = await PriceBookClient.createDealTermDiscountingPolicy(apiData);
        if (response.message === 'success') {
          getDiscountPolicy();
          setSnackBarValues({
            display: true,
            type: 'success',
            message: intl.formatMessage({ id: 'dataSaved' }),
          });
        }
        setIsDealTermLoader(false);
        setDealTermDiscount([{ input1: '', input2: '', input3: '' }]);
      } catch (err) {
        console.log(err, 'failed');
        setIsDealTermLoader(false);
      }
    }
  };

  const openDeleteModal = (data: any): any => {
    const dealTerm = filteredDealTerms.find((item: any) => item.id === data.dealTermId);
    if (dealTerm !== undefined) {
      setDeleteProdName(dealTerm.name);
      setDeleteProdId(data.id);
      setShowDeleteConfirmDialog(true);
    } else {
      setDeleteProdName('');
      setDeleteProdId('');
      setShowDeleteConfirmDialog(false);
    }
  };

  const onDeleteProduct = () => {
    setDeleteLoader(true);
    PriceBookClient.deleteDealTermDiscountingPolicy(selectedPricingBook.id, deleteProdId)
      .then(() => {
        setShowDeleteConfirmDialog(false);
        setDeleteLoader(false);
        const filteredList = dealTermDiscountValues.filter((i: any) => i.id !== deleteProdId);
        setDealTermDiscountValues(filteredList);
        setTimeout(() => {
          setSnackBarValues({
            display: true,
            type: 'success',
            message: intl.formatMessage({ id: 'deleteDealTermDiscountSuccess' }),
          });
        }, 0);
      })
      .catch((err: any) => {
        setDeleteLoader(false);
        setShowDeleteConfirmDialog(false);
        console.error(err);
      });
  };

  const onEditOption = (val: any, index: number) => {
    if (disabledArr.includes(index)) {
      setEditDealTermDiscountIndex(index);
      setEditedDealTerm(dealTermDiscountValues[index]);
      const arr = disabledArr.filter((item) => item !== index);
      setDisabledArr(arr);
    } else {
      setDisabledArr((prev) => [...prev, index]);
    }
  };

  const onEditSaveOption = (val: any) => {
    const obj: any = {};
    obj.price_book_id = selectedPricingBook.id;
    obj.tenant_id = selectedPricingBook.tenant_id;
    obj.deal_term_id = val.dealTermId;
    obj.deal_term_option_id = val.component === 'checkbox' ? null : val.dealTermOptionId;
    obj.value = val.component === 'checkbox' ? val.value : null;
    obj.discount = +val.discount;
    setEditLoader(true);
    const data = {
      pricebook_id: selectedPricingBook.id,
      deal_term_discount_id: val.id,
      data: obj,
    };
    PriceBookClient.editDealTermDiscountingPolicy(data)
      .then((res) => {
        if (res.message === 'success') {
          setEditLoader(false);
          setDealTermDiscountValues((prevValues: any) =>
            prevValues.map((item: any) => (item.dealTermId === val.dealTermId ? val : item)),
          );
          const disabledArrList = [...disabledArr];
          disabledArrList.push(editDealTermDiscountIndex);
          setDisabledArr(disabledArrList);

          setTimeout(() => {
            setSnackBarValues({
              display: true,
              type: 'success',
              message: intl.formatMessage({ id: 'editDealTermDiscountSuccess' }),
            });
          }, 0);
        }
      })
      .catch((err: any) => {
        setEditLoader(false);
        console.error(err);
      });
  };

  const handleDealTermChange = (u: any, index: number) => {
    const { name, value } = u.target;
    const newDealTermDiscountValues = [...dealTermDiscountValues];
    if (name === 'dealTerm') {
      newDealTermDiscountValues[index] = {
        ...newDealTermDiscountValues[index],
        dealTermId: value,
      };
    } else if (name === 'dealTermOption') {
      newDealTermDiscountValues[index] = {
        ...newDealTermDiscountValues[index],
        dealTermOptionId: newDealTermDiscountValues[index].component === 'checkbox' ? null : value,
        value: newDealTermDiscountValues[index].component === 'checkbox' ? value : null,
      };
    } else if (name === 'discount') {
      if (+value < -50 || +value > 100) {
        setIsDiscountPercentLimitErrorEdit(true);
      } else {
        setIsDiscountPercentLimitErrorEdit(false);
      }
      newDealTermDiscountValues[index] = {
        ...newDealTermDiscountValues[index],
        discount: value,
      };
    }
    setDealTermDiscountValues(newDealTermDiscountValues);
  };
  const handleCancel = (index: number) => {
    setDealTermDiscountValues((prevValues: any) =>
      prevValues.map((item: any, i: number) =>
        i === index
          ? {
              dealTermId: editedDealTerm.dealTermId,
              dealTermOptionId: editedDealTerm.dealTermOptionId,
              id: editedDealTerm.id,
              discount: setNumberFormat(editedDealTerm.discount),
              value: editedDealTerm.value,
              component: editedDealTerm.dealTermOptionId === null ? 'checkbox' : 'other',
            }
          : item,
      ),
    );
    setEditedDealTerm({});
    setDisabledArr((prev) => [...prev, index]);
  };
  const setNumberFormat = (discount: number) => {
    const num = +discount;

    let final;
    if (num % 1 !== 0) {
      final = num.toFixed(2);
    } else {
      final = +num.toFixed(0);
    }

    return final.toString().replace(/^0+/, '') || '0';
  };
  const designationList = (index: number, val: string) => {
    if (val === 'edit') {
      if (index === 0) {
        return hierarchyList;
      }
      const lastSelectedId = Object.keys(hierarchyData)[index - 1];
      if (lastSelectedId) {
        const lastSelectedHierarchy = hierarchyList.find((h: any) => h.id === lastSelectedId);
        if (lastSelectedHierarchy) {
          return hierarchyList.filter((d: any) => d.level < lastSelectedHierarchy.level);
        }
        return [];
      }
      return [];
    }
    if (val === 'add') {
      if (index === 0) {
        return sortedHierarchyList;
      }
      const lastSelectedId = addNewHierarchyDesignation[index - 1].designation;
      if (lastSelectedId) {
        const lastSelectedHierarchy = sortedHierarchyList.find((h: any) => h.id === lastSelectedId);
        if (lastSelectedHierarchy) {
          return sortedHierarchyList.filter((d: any) => d.level < lastSelectedHierarchy.level);
        }
        return [];
      }
      return [];
    }
    return [];
  };
  return (
    <>
      <Box sx={commonStyle.bodyContainer}>
        <Grid container rowSpacing={3}>
          <Grid item md={12}>
            <Paper sx={componentStyle.paperContainer}>
              {dropdownComponent(
                'pricebook',
                'pricebook',
                priceBooks,
                selectedPricingBook,
                setSelectedPricingBook,
                pricingBookLoader,
              )}
            </Paper>
          </Grid>
          <Grid item md={12}>
            {hierarchyList.length === 0 && !isHierarchyListLoading ? (
              <Typography sx={{ paddingTop: '10px', paddingLeft: '20px', color: 'red' }}>
                No Org. Hierarchy found to provide the discounting policy. Import the Org. Hierarchy
                under the `Company Hierarchy` or sync it from Salesforce
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      {isGetDiscountPolicyLoading && !isGetDiscountPolicyError && (
        <Box
          sx={{ height: '65vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>
      )}

      {!isGetDiscountPolicyLoading &&
        !isGetDiscountPolicyError &&
        selectedPricingBook &&
        Object.keys(selectedPricingBook).length > 0 && (
          <Box
            sx={{
              backgroundColor: '#E5E5E575',
              marginRight: '20px',
              marginLeft: '10px',
              padding: '40px',
            }}>
            <Typography sx={componentStyle.heading}>
              <FormattedMessage id="hierarchyBasedDiscounts" />
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                marginY: '40px',
                position: 'relative',
                overflowX: 'auto',
                overflowY: 'hidden',
                paddingTop: '20px',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  height: 'fit-content',
                  marginTop: '80px',
                }}>
                {productRows.length > 0 &&
                  productRows.map((p: any) => {
                    return (
                      <Box sx={componentStyle.tableText}>
                        <Tooltip title={p.product}>
                          <Typography
                            sx={{
                              paddingTop: '10px',
                              paddingLeft: '20px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              // maxWidth: '200px',
                            }}
                            key={p.id}>
                            <span>{p.product}</span>
                          </Typography>
                        </Tooltip>
                      </Box>
                    );
                  })}
              </Box>

              {Object.keys(hierarchyData).length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    height: 'fit-content',
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    {Object.entries(hierarchyData).map(
                      ([org_hierarchy_id, items]: [any, any], index: number) => {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '20px',
                            }}>
                            <Grid sx={{ display: 'flex' }}>
                              <Grid sx={{ width: '250px' }}>
                                <FormControl fullWidth>
                                  <InputLabel id="Designation-edit">Designation</InputLabel>

                                  <Select
                                    id="dealTerm"
                                    name="designation"
                                    fullWidth
                                    label="Designation"
                                    value={Object.keys(items)[0]}
                                    onChange={(event) =>
                                      handleSelectChange(
                                        org_hierarchy_id,
                                        items[org_hierarchy_id][0].product_id,
                                        event,
                                      )
                                    }>
                                    {hierarchyList.map((val: any) => (
                                      <MenuItem value={val.id} key={val.id}>
                                        {val.name.length > 25
                                          ? `${val.name.substring(0, 25)}...`
                                          : val.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                            {items[org_hierarchy_id].map((item: any) => {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: '20px',
                                    alignItems: 'center',
                                  }}>
                                  <Box sx={{ width: '251px' }}>
                                    <TextField
                                      id="discount"
                                      name="discount"
                                      variant="outlined"
                                      fullWidth
                                      type="number"
                                      placeholder="Discount"
                                      value={item?.discount || ''}
                                      onChange={(event) =>
                                        handleTextFieldChange(
                                          org_hierarchy_id,
                                          item.product_id,
                                          event,
                                        )
                                      }
                                      onBlur={(event) =>
                                        handleDiscountFormatHirerachy(
                                          org_hierarchy_id,
                                          item.product_id,
                                          event,
                                        )
                                      }
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">%</InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                </Box>
                              );
                            })}
                            <Button onClick={() => handleDeleteHierarchy(org_hierarchy_id, index)}>
                              {deleteHierarchyLoader && deleteHierarchyIndex === index ? (
                                <CircularProgress size={24} sx={{ color: 'white' }} />
                              ) : (
                                <CancelIcon />
                              )}
                            </Button>
                          </Box>
                        );
                      },
                    )}
                  </Box>
                </Box>
              )}
              {addEmptyHirerachyRow.length > 0 &&
                addEmptyHirerachyRow.map((itemm: any, index: number) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                        flexDirection: 'column',
                        height: 'fit-content',
                        width: '250px',
                      }}>
                      {itemm.map((item: any, idx: number) => {
                        return (
                          <>
                            {idx === 0 && (
                              <FormControl fullWidth>
                                <InputLabel id="Designation-add-new">Designation</InputLabel>
                                <Select
                                  id="dealTerm"
                                  name="designation"
                                  fullWidth
                                  label="Designation"
                                  onChange={(e) => handleDesignation(e, index)}>
                                  {designationList(index, 'add').map((val: any) => (
                                    <MenuItem value={val.id} key={val.id}>
                                      {val.name.length > 25
                                        ? `${val.name.substring(0, 25)}...`
                                        : val.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}

                            <TextField
                              id="discount"
                              name="discount"
                              placeholder="Discount"
                              variant="outlined"
                              fullWidth
                              type="number"
                              sx={{ width: '250px' }}
                              value={item.discount}
                              onChange={(e) => handleAddNewDiscount(e, index, itemm, idx)}
                              onBlur={(e) => hirerachyDiscountFormatting(e, index, itemm, idx)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                              }}
                            />
                          </>
                        );
                      })}
                      <Button onClick={() => handleDeleteNewColumn(index)}>
                        <CancelIcon />
                      </Button>
                    </Box>
                  );
                })}
              <Button
                sx={{
                  height: 'max-content',
                  width: 'max-content',
                  marginTop: '5px',
                  minWidth: 'unset',
                  backgroundColor: '#5D5FEF',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  borderRadius: '10px',
                  padding: '10px 20px 10px 10px',
                  color: 'white',
                  ':hover': { backgroundColor: '#5D5FEF' },
                  '&:disabled': {
                    color: 'white',
                    opacity: 0.5,
                  },
                }}
                onClick={addEmptyRow}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    width: '100%',
                    minWidth: 'min-content',
                    flexShrink: 0,
                    whiteSpace: 'nowrap',
                  }}>
                  <PlusIcon /> <FormattedMessage id="addDesignation" />
                </Box>
              </Button>
            </Box>

            {(isEditHierarchyError || isCreateHierarchyError) && (
              <Typography
                sx={{
                  color: 'red',
                  fontSize: 18,
                  marginBottom: '20px',
                }}>
                Please fill the required fields and discount values should be between 0 to 100.
              </Typography>
            )}

            <Paper sx={componentStyle.paperBottomContainer}>
              <Box sx={componentStyle.btnContainer}>
                <Button sx={commonStyle.button} onClick={validateBothApiData}>
                  {editHierarchyLoader || createHierarchyLoader ? (
                    <CircularProgress size={24} sx={{ color: 'white' }} />
                  ) : (
                    <FormattedMessage id="save" />
                  )}
                </Button>
              </Box>
            </Paper>

            {(dealTermDiscountValues.length > 0 || dealTermDiscount.length > 0) && (
              <Box
                sx={{
                  borderRadius: '4px',
                  backgroundColor: '#E5E5E575',
                  paddingY: '20px',
                }}>
                <Typography sx={componentStyle.heading}>
                  <FormattedMessage id="dealTermBasedDiscounts" />
                </Typography>
              </Box>
            )}
            {(dealTermDiscountValues.length > 0 || dealTermDiscount.length > 0) && (
              <Grid container rowSpacing={3}>
                <Grid item md={12} xs={12}>
                  <Paper sx={componentStyle.paperContainerHeading}>
                    <Grid item md={4} sx={{ width: '250px' }}>
                      <Typography className="fieldHeader">
                        <FormattedMessage id="dealTerm" />
                        <span style={{ color: 'red' }}> *</span>
                      </Typography>
                    </Grid>
                    <Grid item md={4} sx={{ width: '250px' }}>
                      <Typography className="fieldHeader">
                        <FormattedMessage id="value" />
                        <span style={{ color: 'red' }}> *</span>
                      </Typography>
                    </Grid>
                    <Grid item md={4} sx={{ width: '250px' }}>
                      <Typography className="fieldHeader">
                        <FormattedMessage id="discount" />
                        <span style={{ color: 'red' }}> *</span>
                      </Typography>
                    </Grid>
                    <Grid item md={4} sx={{ width: '200px' }} />
                  </Paper>
                </Grid>
              </Grid>
            )}
            {dealTermDiscountValues.length > 0 &&
              dealTermDiscountValues.map((item: any, index: number) => {
                const isOptionEditable = dealTermDiscountValues.length !== disabledArr.length;
                const isDisabled =
                  editLoader || deleteLoader || isOptionEditable || dealTermDiscount.length > 0;
                return (
                  <Grid container rowSpacing={3}>
                    <Grid item md={12} xs={12}>
                      <Paper sx={componentStyle.paperContainerDiscount}>
                        <Grid item md={4} sx={{ width: '250px' }}>
                          <FormControl fullWidth sx={{ marginTop: '10px' }}>
                            <InputLabel id="dealTerm">
                              <FormattedMessage id="dealTerm" />
                            </InputLabel>
                            <Select
                              labelId="dealTerm"
                              disabled={disabledArr.includes(index)}
                              label={<FormattedMessage id="dealTerm" />}
                              id="dealTerm"
                              name="dealTerm"
                              fullWidth
                              value={item.dealTermId}
                              sx={{ width: '250px' }}
                              onChange={(u) => handleDealTermChange(u, index)}>
                              {filteredDealTerms.map((dealTerm: any) => (
                                <MenuItem value={dealTerm.id} key={dealTerm.id}>
                                  {dealTerm.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sx={{ width: '250px' }}>
                          <FormControl fullWidth sx={{ marginTop: '10px' }}>
                            <InputLabel id="value">
                              <FormattedMessage id="value" />
                            </InputLabel>
                            <Select
                              labelId="dealTermOption"
                              label={<FormattedMessage id="dealTermOption" />}
                              id="dealTermOption"
                              disabled={disabledArr.includes(index)}
                              name="dealTermOption"
                              sx={{ width: '250px' }}
                              fullWidth
                              value={
                                item.component === 'checkbox' ? item.value : item.dealTermOptionId
                              }
                              onChange={(u) => handleDealTermChange(u, index)}>
                              {item.component === 'checkbox'
                                ? booleanArray.map((option: any) => (
                                    <MenuItem value={option.id} key={option.id}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                : getOptions(item.dealTermId).map((option: any) => (
                                    <MenuItem value={option.id} key={option.id}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sx={{ width: '250px' }}>
                          <TextField
                            label={<FormattedMessage id="discount" />}
                            id="discount"
                            name="discount"
                            variant="outlined"
                            disabled={disabledArr.includes(index)}
                            fullWidth
                            type="number"
                            value={item.discount}
                            sx={{ marginTop: '10px', width: '250px' }}
                            onChange={(u) => handleDealTermChange(u, index)}
                            onBlur={(u) => DiscountToFixed(u, index)}
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '200px',
                          }}>
                          {disabledArr.includes(index) && (
                            <>
                              <Button
                                onClick={() => onEditOption(item, index)}
                                disabled={isDisabled}>
                                <EditIcon />
                              </Button>
                              <Button onClick={() => openDeleteModal(item)} disabled={isDisabled}>
                                <DeleteIcon color={isDisabled ? 'disabled' : 'error'} />
                              </Button>
                            </>
                          )}
                          {!disabledArr.includes(index) && (
                            <>
                              <Button
                                onClick={() => onEditSaveOption(item)}
                                disabled={editLoader || isDiscountPercentLimitErrorEdit}>
                                {editLoader ? (
                                  <CircularProgress color="inherit" size={24} />
                                ) : (
                                  <FormattedMessage id="save" />
                                )}
                              </Button>
                              <Button onClick={() => handleCancel(index)} disabled={editLoader}>
                                <FormattedMessage id="cancel" />
                              </Button>
                            </>
                          )}
                        </Grid>
                      </Paper>
                      {isDiscountPercentLimitErrorEdit && !disabledArr.includes(index) && (
                        <Typography sx={{ color: 'red', marginY: '18px' }}>
                          Discount should be between -50% to 100 %
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                );
              })}

            {dealTermDiscount &&
              dealTermDiscount.length > 0 &&
              dealTermDiscount.map((_item: any, idx: number) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid container rowSpacing={3} key={idx}>
                    <Grid item md={12}>
                      <Paper sx={componentStyle.paperContainerDiscount}>
                        <Grid item md={4} sx={{ width: '300px' }}>
                          <FormControl fullWidth sx={{ marginTop: '10px' }}>
                            <InputLabel id="dealTerm">
                              <FormattedMessage id="dealTerm" />
                            </InputLabel>
                            <Select
                              labelId="dealTerm"
                              label={<FormattedMessage id="dealTerm" />}
                              id="dealTerm"
                              name="input1"
                              fullWidth
                              value={dealTermDiscount[idx].input1}
                              onChange={(u) => handleChange(u, idx)}>
                              {filteredDealTerms.map((dealTerm: any) => (
                                <MenuItem value={dealTerm.id} key={dealTerm.id}>
                                  {dealTerm.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sx={{ width: '300px' }}>
                          <FormControl fullWidth sx={{ marginTop: '10px' }}>
                            <InputLabel id="value">
                              <FormattedMessage id="value" />
                            </InputLabel>
                            <Select
                              labelId="dealTermOption"
                              label={<FormattedMessage id="dealTermOption" />}
                              id="dealTermOption"
                              name="input2"
                              fullWidth
                              value={dealTermDiscount[idx].input2}
                              onChange={(u) => handleChange(u, idx)}>
                              {getOptions(dealTermDiscount[idx].input1).map((option: any) => (
                                <MenuItem value={option.id}>{option.label}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sx={{ width: '300px' }}>
                          <TextField
                            label={<FormattedMessage id="discount" />}
                            id="discount"
                            name="input3"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={dealTermDiscount[idx].input3}
                            sx={{ marginTop: '10px' }}
                            onChange={(u) => handleChange(u, idx)}
                            onBlur={(e) => DiscountToFixed(e, idx)}
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '300px',
                          }}>
                          <Button
                            onClick={() => handleRemoveDiscountRow(idx)}
                            sx={componentStyle.deleteIcon}>
                            <DeleteIcon color="error" />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                );
              })}

            {isErrorDealTerm && dealTermDiscount.length > 0 && (
              <Typography sx={{ color: 'red', marginY: '18px' }}>
                Please fill all required fields before saving or delete incomplete row.
              </Typography>
            )}
            {isDiscountPercentLimitError && !isErrorDealTerm && dealTermDiscount.length > 0 && (
              <Typography sx={{ color: 'red', marginY: '18px' }}>
                Discount should be between -50% and 100%.
              </Typography>
            )}

            {filteredDealTerms && filteredDealTerms.length > 0 && (
              <Box sx={{ marginY: '20px' }}>
                <Button
                  onClick={addDealTermDiscountRow}
                  sx={styles.dialogButton}
                  disabled={
                    (dealTermDiscountValues.length > 0 &&
                      dealTermDiscountValues.length !== disabledArr.length) ||
                    IsdealTermLoader ||
                    deleteLoader
                  }>
                  <FormattedMessage id="addDiscount" />
                </Button>
              </Box>
            )}

            {dealTermDiscount && dealTermDiscount.length > 0 && (
              <Paper sx={componentStyle.paperBottomContainer}>
                <Box sx={componentStyle.btnContainer}>
                  <Button
                    sx={commonStyle.button}
                    onClick={saveDealTermBasedDiscount}
                    disabled={IsdealTermLoader}>
                    {IsdealTermLoader ? (
                      <CircularProgress size={24} sx={{ color: 'white' }} />
                    ) : (
                      <FormattedMessage id="save" />
                    )}
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        )}

      {showDeleteConfirmDialog ? (
        <ConfirmDialog
          dialogConfig={{
            name: 'confirmDelete',
            text: 'confirmMessageWithName',
            open: showDeleteConfirmDialog,
            productName: deleteProdName,
            loader: deleteLoader,
            handleConfirm: onDeleteProduct,

            handleCancel: () => {
              setDeleteProdId('');
              setDeleteProdName('');
              setShowDeleteConfirmDialog(false);
            },
          }}
        />
      ) : null}
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};
export default injectIntl(DiscountingPolicy);
