import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import FeatureRepositoryClient from '../../api/FeatureRepository/FeatureRepositoryAPI';
import DialogBox from '../../components/DialogBox/DialogBox';
import { getFeatureGroupFields } from '../../constants/dialogBoxConstants';
import { IFeatureGroup } from '../../models/repository';
import { hideEditFeatureGroupPopup } from '../../store/feature_repository/editFeatureGroup.slice';
import { REGEX } from '../../utils/helperService';
import { ISnackBar } from '../../models/common';
import Snackbar from '../../components/Snackbar/Snackbar';

interface IProps {
  onEdit: (data: IFeatureGroup) => void;
  setLoader: (value: boolean) => void;
}

const EditFeatureGroup: React.FC<IProps> = ({ onEdit, setLoader }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: any) => state.editFeatureGroup.isOpen);
  const featureGroupToEdit = useSelector((state: any) => state.editFeatureGroup.featureGroup);
  const [featureGroup, setFeatureGroup] = useState<any>('');
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);

  const closePopup = () => {
    dispatch(hideEditFeatureGroupPopup());
  };
  const saveFeature = async () => {
    try {
      setLoader(true);
      dispatch(hideEditFeatureGroupPopup());
      const response = await FeatureRepositoryClient.editFeatureGroup(featureGroupToEdit.id, {
        name: featureGroup.name.trim(),
        external_name: featureGroup.external_name || null,
        external_description: featureGroup.external_description || null,
        features: featureGroup.features,
      } as IFeatureGroup);
      if (response.message === 'success') {
        onEdit({ ...featureGroup, ...response.data } as IFeatureGroup);
        setTimeout(() => {
          setSnackBarValues({
            display: true,
            type: 'success',
            message: 'Feature group updated successfully!',
          });
        }, 0);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };
  useEffect(() => {
    setFeatureGroup(featureGroupToEdit);
  }, [featureGroupToEdit]);

  return (
    <>
      <DialogBox
        dialogConfig={{
          name: 'editFeatureGroup',
          fields: getFeatureGroupFields(featureGroup, setFeatureGroup),
          open: isOpen,
          handleClose: closePopup,
          handleSave: saveFeature,
          initialValues: {
            name: featureGroup.name ?? '',
            external_name: featureGroup.external_name ?? '',
            external_description: featureGroup.external_description ?? '',
          },
          schema: Yup.object({
            name: Yup.string()
              .trim()
              .required('Please enter feature group name')
              .matches(
                REGEX,
                'Name should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
              )
              .max(50, 'Name cannot exceed 50 characters'),
            external_name: Yup.string().max(50, 'External name cannot exceed 50 characters'),
            external_description: Yup.string().max(
              70,
              'External description cannot exceed 70 characters',
            ),
          }),
        }}
      />
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};

export default EditFeatureGroup;
