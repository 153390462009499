/* eslint-disable no-nested-ternary */
import InfoIcon from '@mui/icons-material/Info';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
// import ContractClient from '../../api/Contract/ContractAPI';
import PricingCalculatorClient from '../../api/PricingCalculator/PricingCalculatorAPI';
import Snackbar from '../../components/Snackbar/Snackbar';
import { QuoteStatus, pricingColumnFields } from '../../constants/constants';
import { pricingTableColumn } from '../../mocks/pricingCalculator';
import { ISnackBar } from '../../models/common';
import {
  setPriceBookDetails,
  setSelectedQuoteDetails,
  setSelectedQuote,
  setQuoteVersions,
  setSelectedPricingBook,
} from '../../store/pricing_calculator/pricingCalculator.slice';
import commonStyle from '../../styles/commonStyle';
// import ConfigContract from './ConfigContract';
import NotesPopup from './NotesPopup';
import componentStyle from './PricingCalculatorStyle';
import './calculator.scss';
import { handleDownload } from '../../utils/helperService';

import ConfirmDialog from '../../components/DialogBox/ConfirmDialog';
import SaveInput from '../../components/SaveInput/SaveInput';
import { isCurrencyCodeExists } from '../../utils/stringUtil';

interface IProps {
  intl: any;
  setDisableEscalation: (value: boolean) => void;
  disableEscalation: boolean;
  checkSaveQuoteCondition: () => boolean;
  quoteName: string;
  setQuoteName: (value: string) => void;
  saveLoader: boolean;
  saveQuote: () => void;
  setDealTermValues: any;
  dealTermValues: any;
  filteredDealTermSchema: any;
  dealTermDiscountsAll: any[];

  selectedDealTermCheckbox: {
    deal_term_id: string;
    deal_term_option_id: string | null;
    value: string | null;
    component: string;
  }[];
  setSelectedDealTermCheckbox: any;
  selectedDealTermCheckboxArr: string[];
  setSelectedDealTermCheckboxArr: any;
}

const RightPortion: React.FC<IProps> = ({
  intl,
  disableEscalation,
  setDisableEscalation,
  checkSaveQuoteCondition,
  quoteName,
  setQuoteName,
  saveLoader,
  saveQuote,
  dealTermValues,
  setDealTermValues,
  filteredDealTermSchema,
  dealTermDiscountsAll,
  selectedDealTermCheckbox,
  setSelectedDealTermCheckbox,
  selectedDealTermCheckboxArr,
  setSelectedDealTermCheckboxArr,
}): ReactElement => {
  const dispatch = useDispatch();
  const prevDealTermValuesRef = useRef();
  const userOperations = useSelector((state: any) => state.auth.userOperations);
  const selectedPricingProducts = useSelector(
    (state: any) => state.pricingCalculator.selectedPricingProducts,
  );

  const priceBookDetails = useSelector((state: any) => state.pricingCalculator.priceBookDetails);
  const [openNote, setOpenNote] = useState(false);
  // const [configPopup, setConfigPopup] = useState(false);
  const [changedCoreRowIndex, setChangeCoreRowIndex] = useState<any>(null);
  const [changedAddonsRowIndex, setChangeAddonRowIndex] = useState<any>(null);
  const selectedQuoteDetails = useSelector(
    (state: any) => state.pricingCalculator.selectedQuoteDetails,
  );
  const selectedOpportunity = useSelector(
    (state: any) => state.pricingCalculator.selectedOpportunity,
  );
  const userId = useSelector((state: any) => state.auth.userId);
  const [apiLoader, setLoader] = useState(false);
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  // const [forwardToDealDeskLoader, setForwardToDealDeskLoader] = useState<boolean>(false);
  const [escalateLoader, setEscalateLoader] = useState<boolean>(false);
  // const [contractSpecificLoader, setContractSpecificLoader] = useState<boolean>(false);
  const [resendToAeLoader, setResendToAeLoader] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isApprovalRequired, setIsApprovalRequired] = useState<boolean>(false);
  const [grandTotal, setGrandTotal] = useState([]);
  const [calcBody, setCalcBody] = useState<any>({});
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteProdId, setDeleteProdId] = useState<string>('');
  const [deleteProdName, setDeleteProdName] = useState<string>('');
  const [isNewQuoteError, setIsNewQuoteError] = useState(false);
  const [isErrorInCalculationApi, setIsErrorInCalculationApi] = useState(false);
  const [isDealTermValidationError, setIsDealTermValidationError] = useState(false);
  const [isCoreQtyError, setIsCorQtyError] = useState(false);
  const [requiredNotFilledDealTerms, setRequiredNotFilledDealTerms] = useState<string[]>([]);
  const [IsselectedProduct, setIsSelectedProduct] = useState(true);
  const [totalDealTermDiscountedVal, setTotalDealTermDiscountedVal] = useState(0);

  const allPriceBookDiscounts = useSelector(
    (state: any) => state.pricingCalculator.allPriceBookDiscounts,
  );
  const selectedPricingBook = useSelector(
    (state: any) => state.pricingCalculator.selectedPricingBook,
  );
  const hierachyDiscounts = allPriceBookDiscounts?.hierarchy_based_discount;

  // whenever priceBookDetails changes, it runs calCulateTotal func
  useEffect(() => {
    if (priceBookDetails && priceBookDetails.id) {
      calCulateTotal(priceBookDetails);
    }
    // eslint-disable-next-line
  }, [priceBookDetails]);

  // this effect changes setIsApprovalRequired  based on selectedPricingProducts
  useEffect(() => {
    setIsApprovalRequired(false);
    selectedPricingProducts.forEach((item: any) => {
      const totalDiscount = item?.total?.filter((i: any) => i.key === 'discount');
      if (item.discount && totalDiscount[0]?.value && item.discount < totalDiscount[0]?.value) {
        setIsApprovalRequired(true);
      }
    });
  }, [selectedPricingProducts]);

  // this sets grand total row in table
  useEffect(() => {
    const total: any = [];
    selectedPricingProducts.forEach((pr: any, i: number) => {
      pr.total.forEach((t: any) => {
        if (i === 0) {
          total.push(t);
        } else {
          const index = total.findIndex((v: any) => v.key === t.key);
          if (index !== -1 && total[index].value && t.value) {
            total[index] = { ...total[index], ...{ value: t.value + total[index].value } };
          }
        }
      });
    });
    if (total.length) {
      const discountIndex = total.findIndex((k: any) => k.key === 'discount');
      const listTotalIndex = total.findIndex(
        (k: any) => k.key === 'list_total_price' && k.value !== null && k.value !== 0,
      );
      const discountTotalIndex = total.findIndex(
        (k: any) => k.key === 'discounted_total_price' && k.value !== null && k.value !== 0,
      );
      if (
        discountIndex > -1 &&
        listTotalIndex > -1 &&
        discountTotalIndex > -1
        // && total[listTotalIndex].value > total[discountTotalIndex].value
      ) {
        const discount = (
          ((total[listTotalIndex].value - total[discountTotalIndex].value) /
            total[listTotalIndex].value) *
          100
        ).toFixed(2);
        total[discountIndex] = { ...total[discountIndex], value: +discount };

        if (totalDealTermDiscountedVal !== 0) {
          total[discountIndex] = {
            ...total[discountIndex],
            value: +totalDealTermDiscountedVal + +discount,
          };
          const i = (total[listTotalIndex].value * total[discountIndex].value) / 100;
          const newDiscountTotalValue = total[listTotalIndex].value - i;
          total[discountTotalIndex] = {
            ...total[discountTotalIndex],
            value: +newDiscountTotalValue,
          };
        }
      }
    }
    setGrandTotal(total);
  }, [selectedPricingProducts, totalDealTermDiscountedVal]);

  // this sets setIsNewQuoteError
  useEffect(() => {
    if (selectedOpportunity.id) {
      setIsNewQuoteError(false);
    }
  }, [selectedOpportunity.id]);

  // whenever dealTermValues gets changed, it checks if dealTermDiscountsAll is available against it. if not it removes dealTermId from selectedDealTermCheckboxArr
  useEffect(() => {
    const updatedCheckboxArr = selectedDealTermCheckboxArr.filter((dealTermId) => {
      const dealTerm = dealTermValues.find((term: any) => term.deal_term_id === dealTermId);

      if (dealTerm) {
        return !handleIsCheckboxDisabled(
          dealTerm.deal_term_id,
          dealTerm.component,
          dealTerm.deal_term_option_id,
          dealTerm.value,
        );
      }
      return true;
    });
    if (updatedCheckboxArr.length !== selectedDealTermCheckboxArr.length) {
      setSelectedDealTermCheckboxArr(updatedCheckboxArr);
    }
  }, [dealTermValues]);

  // it setSelectedDealTermCheckbox based on dealTermId present in selectedDealTermCheckboxArr
  useEffect(() => {
    if (selectedDealTermCheckboxArr.length > 0 && dealTermValues.length > 0) {
      const result = dealTermValues
        .filter((dealTerm: any) => selectedDealTermCheckboxArr.includes(dealTerm.deal_term_id))
        .map((dealTerm: any) => ({
          deal_term_id: dealTerm.deal_term_id,
          deal_term_option_id: dealTerm.deal_term_option_id,
          value: dealTerm.value,
          component: dealTerm.component,
        }));

      setSelectedDealTermCheckbox(result);
    } else {
      setSelectedDealTermCheckbox([]);
    }
  }, [selectedDealTermCheckboxArr, dealTermValues]);

  // it setTotalDealTermDiscountedVal in grandtotal row. it adds all the discount present in dealTermDiscountsAll array by filtering based on dealTermId and dealTermOptionId

  useEffect(() => {
    if (selectedDealTermCheckbox.length > 0 && dealTermDiscountsAll.length > 0) {
      const mergeDealTermDiscountsAllArray = dealTermDiscountsAll.map((discount) => {
        const matchingDealTerm = dealTermValues.find(
          (term: any) => term.deal_term_id === discount.deal_term_id,
        );

        if (matchingDealTerm) {
          return {
            ...discount,
            component: matchingDealTerm.component,
          };
        }
        return discount;
      });
      const totalDiscount = selectedDealTermCheckbox.reduce((acc, selectedTerm) => {
        const matchingDiscounts = mergeDealTermDiscountsAllArray.filter((discount) =>
          discount.component === 'checkbox'
            ? discount.deal_term_id === selectedTerm.deal_term_id &&
              discount.value === selectedTerm.value
            : discount.deal_term_id === selectedTerm.deal_term_id &&
              discount.deal_term_option_id === selectedTerm.deal_term_option_id,
        );

        const sumDiscount = matchingDiscounts.reduce(
          (sum, discount) => sum + parseFloat(discount.discount),
          0,
        );

        return acc + sumDiscount;
      }, 0);

      setTotalDealTermDiscountedVal(totalDiscount);
    } else {
      setTotalDealTermDiscountedVal(0);
    }
  }, [selectedDealTermCheckbox, dealTermDiscountsAll]);

  // const openConfigPopup = () => {
  //   setConfigPopup(true);
  // };
  const onInputChange = useCallback(
    (
      details: any,
      productId: string,
      tierId: string,
      rowIndex: number,
      colIndex: number,
      value: any,
      type: string,
      key: string,
      payloadOb: any,
      timer?: string,
    ) => {
      const tempData = JSON.parse(JSON.stringify(details));
      let productIndex = -1;
      let tierIndex = -1;
      productIndex = tempData.products.findIndex((item: any) => item.product_id === productId);
      if (productIndex > -1) {
        tierIndex = tempData.products[productIndex].tier_details.findIndex(
          (item: any) => item.tier_id === tierId,
        );
      }
      tempData.products[productIndex].tier_details[tierIndex].details[type].rows[rowIndex].values[
        colIndex
      ].value = Number(value);
      tempData.products[productIndex].tiers = tempData.products[productIndex].tier_details.filter(
        (tier: any) => tier.checked,
      );
      if (timer && timer === 'withoutDebounce') {
        loadColumnDataWithoutDebounce(
          productId,
          productIndex,
          tierIndex,
          rowIndex,
          value,
          type,
          key,
          tempData,
          payloadOb,
        );
      } else {
        loadColumnData(
          productId,
          productIndex,
          tierIndex,
          rowIndex,
          value,
          type,
          key,
          tempData,
          payloadOb,
        );
      }
      dispatch(setPriceBookDetails(tempData));
    },
    // eslint-disable-next-line
    [],
  );

  const loadColumnDataWithoutDebounce = _.debounce(
    (
      productId,
      productIndex: number,
      tierIndex: number,
      rowIndex: number,
      value: any,
      type: string,
      key: string,
      tempData: any,
      payloadOb: any,
    ) => {
      if (key === 'qty' || key === 'discounted_unit_price' || key === 'discounted_total_price') {
        getTableData(productId, productIndex, tierIndex, rowIndex, tempData, type, key, payloadOb);
      }
    },
    1,
  );

  const loadColumnData = _.debounce(
    (
      productId,
      productIndex: number,
      tierIndex: number,
      rowIndex: number,
      value: any,
      type: string,
      key: string,
      tempData: any,
      payloadOb: any,
    ) => {
      if (key === 'qty' || key === 'discounted_unit_price' || key === 'discounted_total_price') {
        getTableData(productId, productIndex, tierIndex, rowIndex, tempData, type, key, payloadOb);
      }
    },
    1500,
  );

  const onResetRow = (
    details: any,
    productId: string,
    tierId: string,
    rowIndex: number,
    rowData: any,
    type: string,
    payloadOb: any,
  ) => {
    const payLoadTemp = payloadOb[productId] || {};
    const colIndex = rowData.values?.findIndex((c: any) => c.key === 'qty');
    if (colIndex > -1 && _.isNumber(rowData.values[colIndex].value)) {
      if (type === 'core') {
        if (_.has(payLoadTemp, ['discounted_total_price_dict', rowData.metric_column.key])) {
          delete payLoadTemp.discounted_total_price_dict[rowData.metric_column.key];
        }
        if (_.has(payLoadTemp, ['discounted_list_unit_price_dict', rowData.metric_column.key])) {
          delete payLoadTemp.discounted_list_unit_price_dict[rowData.metric_column.key];
        }
      }
      if (type === 'addons') {
        const addonIndex = payLoadTemp?.addons?.findIndex(
          (d: any) => d.id === rowData.id || d.id === rowData.addon_id,
        );
        if (addonIndex > -1 && rowData.addonRow) {
          if (
            _.has(payLoadTemp.addons[addonIndex], [
              'discounted_total_price_dict',
              rowData.metric_column.key,
            ])
          ) {
            delete payLoadTemp.addons[addonIndex].discounted_total_price_dict[
              rowData.metric_column.key
            ];
          }
          if (
            _.has(payLoadTemp.addons[addonIndex], [
              'discounted_list_unit_price_dict',
              rowData.metric_column.key,
            ])
          ) {
            delete payLoadTemp.addons[addonIndex].discounted_list_unit_price_dict[
              rowData.metric_column.key
            ];
          }
        } else if (addonIndex > -1) {
          if (_.has(payLoadTemp.addons[addonIndex], 'discounted_total_price_dict')) {
            delete payLoadTemp.addons[addonIndex].discounted_total_price_dict;
          }
        }
      }

      onInputChange(
        details,
        productId,
        tierId,
        rowIndex,
        colIndex,
        rowData.values[colIndex].value,
        type,
        'qty',
        { ...payloadOb, [productId]: payLoadTemp },
      );
    }
  };

  const getTableData = async (
    productId: string,
    productIndex: number,
    tierIndex: number,
    rowIndex: number,
    productArray: any,
    type: string,
    valueKey: string,
    payloadOb: any,
  ) => {
    try {
      const tempArray = JSON.parse(JSON.stringify(productArray));
      if (type === 'core') {
        setChangeCoreRowIndex(rowIndex);
      }
      if (type === 'addons') {
        setChangeAddonRowIndex(rowIndex);
      }

      const body = getApiBody(
        productId,
        tempArray.products[productIndex].tier_details[tierIndex].details,
        type,
        valueKey,
        rowIndex,
        payloadOb,
      );
      const response = await PricingCalculatorClient.pricingModelCalculation(
        tempArray.products[productIndex].pricing_model_id,
        tempArray.products[productIndex].tier_details[tierIndex].tier_id,
        body,
      );
      if (response.message === 'success') {
        setIsApprovalRequired(false);
        setDisableEscalation(true);
        if (response.data.addon_output) {
          response.data.addon_output.map((item: any) => {
            Object.keys(item).map((parentKey) => {
              Object.keys(item[parentKey]).map((subKey) => {
                if (pricingColumnFields.includes(subKey)) {
                  const addonIndex = tempArray.products[productIndex].tier_details[
                    tierIndex
                  ].details.addons.rows.findIndex((adb: any) => {
                    return adb.id === parentKey || adb.addon_id === parentKey;
                  });
                  if (addonIndex > -1) {
                    const findIndex = tempArray.products[productIndex].tier_details[
                      tierIndex
                    ].details.addons.rows[addonIndex].values.findIndex(
                      (field: any) => field.key === subKey,
                    );
                    if (findIndex > -1) {
                      tempArray.products[productIndex].tier_details[tierIndex].details.addons.rows[
                        addonIndex
                      ].values[findIndex].value = item[parentKey][subKey];
                    }
                  }
                } else {
                  const addonIndex = tempArray.products[productIndex].tier_details[
                    tierIndex
                  ].details.addons.rows.findIndex((adb: any) => {
                    if (adb.metric_column && adb.addon_id === parentKey) {
                      return adb.metric_column.key === subKey;
                    }
                    return false;
                  });
                  if (addonIndex > -1) {
                    Object.keys(item[parentKey][subKey]).map((key) => {
                      const findIndex = tempArray.products[productIndex].tier_details[
                        tierIndex
                      ].details.addons.rows[addonIndex].values.findIndex(
                        (field: any) => field.key === key,
                      );
                      if (findIndex > -1) {
                        tempArray.products[productIndex].tier_details[
                          tierIndex
                        ].details.addons.rows[addonIndex].values[findIndex].value =
                          item[parentKey][subKey][key];
                      }
                      return null;
                    });
                  }
                }
                return null;
              });
              return null;
            });
            return null;
          });
        }
        Object.keys(response.data).map((key) => {
          const findValuesColIndex = tempArray.products[productIndex].tier_details[
            tierIndex
          ].details.core.rows.findIndex((row: any) => row.metric_column.key === key);
          if (findValuesColIndex > -1) {
            Object.keys(response.data[key]).forEach((parentKey) => {
              const findIndex = tempArray.products[productIndex].tier_details[
                tierIndex
              ].details.core.rows[findValuesColIndex].values.findIndex(
                (field: any) => field.key === parentKey,
              );
              if (findIndex > -1) {
                tempArray.products[productIndex].tier_details[tierIndex].details.core.rows[
                  findValuesColIndex
                ].values[findIndex].value = response.data[key][parentKey];
              }
            });
          }
          return null;
        });
        dispatch(setPriceBookDetails(tempArray));
        setIsErrorInCalculationApi(false);
      }
      setChangeCoreRowIndex(null);
      setChangeAddonRowIndex(null);
    } catch (e) {
      console.log(e);
      setChangeCoreRowIndex(null);
      setChangeAddonRowIndex(null);
      setIsErrorInCalculationApi(true);
    }
  };
  const getApiBody = (
    productId: string,
    details: any,
    type: string,
    valueKey: string,
    rowIndex: number,
    payloadOb: any,
  ) => {
    const prevPostBody = payloadOb[productId] ?? {};
    let body: any = {
      output_keys: {} as any,
      quantity_dict: {} as any,
      addons: [] as any,
    };
    if (type === 'core') {
      body = { ...body, addons: prevPostBody.addons ?? [] };
      const discountedListUnitPrice = {} as any;
      const discountedTotalPrice = {} as any;
      details?.core?.rows.map((item: any, i: number) => {
        const qty = item.values.find((value: any) => value.key === 'qty');
        if (qty?.key && qty.value !== undefined && qty.value !== null) {
          body.output_keys[item?.metric_column?.key] = item.output_column.key;
          body.quantity_dict[item?.metric_column?.key] = qty.value;
        }
        if (valueKey === 'discounted_unit_price' && rowIndex === i) {
          const discountUnit = item.values.find(
            (value: any) => value.key === 'discounted_unit_price',
          );
          if (
            (discountUnit?.key &&
              discountUnit.value !== undefined &&
              discountUnit.value !== null) ||
            (_.isObject(discountUnit.value) && !_.isEmpty(discountUnit.value))
          ) {
            const ob = {} as any;
            if (item.unit_columns.length) {
              item.unit_columns.map((u: any) => {
                ob[u.key] =
                  _.isObject(discountUnit.value) && Object.keys(discountUnit.value).includes(u.key)
                    ? discountUnit.value[u.key]
                    : discountUnit.value;
                return null;
              });
            }
            discountedListUnitPrice[item?.metric_column?.key] = ob;
            if (
              prevPostBody?.discounted_total_price_dict &&
              Object.keys(prevPostBody?.discounted_total_price_dict).includes(
                item?.metric_column?.key,
              )
            ) {
              delete prevPostBody.discounted_total_price_dict[item?.metric_column?.key];
            }
          }
        }
        if (valueKey === 'discounted_total_price' && rowIndex === i) {
          const discountedTotal = item.values.find(
            (value: any) => value.key === 'discounted_total_price',
          );
          if (
            discountedTotal?.key &&
            discountedTotal.value !== undefined &&
            discountedTotal.value !== null
          ) {
            discountedTotalPrice[item?.metric_column?.key] = discountedTotal.value;
            if (
              prevPostBody?.discounted_list_unit_price_dict &&
              Object.keys(prevPostBody?.discounted_list_unit_price_dict).includes(
                item?.metric_column?.key,
              )
            ) {
              delete prevPostBody.discounted_list_unit_price_dict[item?.metric_column?.key];
            }
          }
        }
        return null;
      });

      body.discounted_list_unit_price_dict = {
        ...prevPostBody?.discounted_list_unit_price_dict,
        ...discountedListUnitPrice,
      };
      body.discounted_total_price_dict = {
        ...prevPostBody?.discounted_total_price_dict,
        ...discountedTotalPrice,
      };
      if (_.isEmpty(body.discounted_list_unit_price_dict)) {
        body = _.omit(body, 'discounted_list_unit_price_dict');
      }
      if (_.isEmpty(body.discounted_total_price_dict)) {
        body = _.omit(body, 'discounted_total_price_dict');
      }
    }
    if (type === 'addons') {
      body = _.isEmpty(prevPostBody) ? body : { ..._.omit(prevPostBody, ['addons']), addons: [] };
      details?.addons?.rows.map((item: any, index: number) => {
        if (index === rowIndex) {
          if (item.values.length) {
            const findAddon = body.addons.findIndex(
              (adb: any) => adb.id === item.id || adb.id === item.addon_id,
            );
            const discountedListUnitPrice = {} as any;
            const discountedTotalPrice = {} as any;
            const qty = item.values.find((value: any) => value.key === 'qty');
            if (qty?.key && qty.value !== undefined && qty.value !== null) {
              if (item?.metric_column?.key) {
                if (findAddon > -1) {
                  body.addons[findAddon].units[item.metric_column.key] = qty.value;
                  body.addons[findAddon].addon_output_keys[item.metric_column.key] =
                    item.output_column.key;
                } else {
                  body.addons.push({
                    id: item.addon_id ?? item.id,
                    units: {
                      [item.metric_column.key]: qty.value,
                    },
                    addon_output_keys: {
                      [item.metric_column.key]: item.output_column.key,
                    },
                  });
                }
              } else {
                body.addons.push({ id: item.addon_id ?? item.id, units: qty.value });
              }
            }
            if (valueKey === 'discounted_unit_price') {
              const discountUnit: any = item.values.find(
                (value: any) => value.key === 'discounted_unit_price',
              );
              if (
                (discountUnit?.key &&
                  discountUnit.value !== undefined &&
                  discountUnit.value !== null) ||
                (_.isObject(discountUnit.value) && !_.isEmpty(discountUnit.value))
              ) {
                if (item?.metric_column?.key) {
                  if (findAddon > -1) {
                    const rowInd = details.addons.rows.findIndex(
                      (r: any) => r?.customAddonMetric === true,
                    );
                    const configIndex = details.addons.rows[rowInd].fields.findIndex(
                      (fi: any) => fi.metric_column.key === item.metric_column.key,
                    );
                    const ob = {} as any;
                    if (details.addons.rows[rowInd].fields[configIndex].unit_columns.length) {
                      details.addons.rows[rowInd].fields[configIndex].unit_columns.map((u: any) => {
                        ob[u.key] = discountUnit.value;
                        return null;
                      });
                    }
                    discountedListUnitPrice[item.metric_column.key] = ob;
                    if (
                      prevPostBody?.addons[findAddon]?.discounted_total_price_dict &&
                      Object.keys(
                        prevPostBody?.addons[findAddon]?.discounted_total_price_dict,
                      ).includes(item?.metric_column?.key)
                    ) {
                      delete prevPostBody?.addons[findAddon].discounted_total_price_dict[
                        item?.metric_column?.key
                      ];
                    }
                  }
                }
              }
            }
            if (valueKey === 'discounted_total_price') {
              const discountTotal: any = item.values.find(
                (value: any) => value.key === 'discounted_total_price',
              );
              if (
                (discountTotal?.key &&
                  discountTotal.value !== undefined &&
                  discountTotal.value !== null) ||
                (_.isObject(discountTotal.value) && !_.isEmpty(discountTotal.value))
              ) {
                if (item?.metric_column?.key) {
                  if (findAddon > -1) {
                    discountedTotalPrice[item.metric_column.key] = discountTotal.value;
                    if (
                      prevPostBody?.addons[findAddon]?.discounted_list_unit_price_dict &&
                      Object.keys(
                        prevPostBody?.addons[findAddon]?.discounted_list_unit_price_dict,
                      ).includes(item?.metric_column?.key)
                    ) {
                      delete prevPostBody?.addons[findAddon].discounted_list_unit_price_dict[
                        item?.metric_column?.key
                      ];
                    }
                  } else {
                    const addon = body.addons.findIndex(
                      (adb: any) => adb.id === item.id || adb.id === item.addon_id,
                    );
                    if (addon > -1) {
                      body.addons[addon].discounted_total_price_dict = {};
                      body.addons[addon].discounted_total_price_dict[item?.metric_column?.key] =
                        discountTotal.value;
                    }
                  }
                } else {
                  const addon = body.addons.findIndex(
                    (adb: any) => adb.id === item.id || adb.id === item.addon_id,
                  );
                  if (addon > -1) {
                    body.addons[addon].discounted_total_price_dict = discountTotal.value;
                  }
                }
              }
            }
            if (findAddon > -1 && body.addons[findAddon]) {
              body.addons[findAddon].discounted_list_unit_price_dict = {
                ...prevPostBody.addons[findAddon]?.discounted_list_unit_price_dict,
                ...discountedListUnitPrice,
              };
              body.addons[findAddon].discounted_total_price_dict = {
                ...prevPostBody?.addons[findAddon].discounted_total_price_dict,
                ...discountedTotalPrice,
              };
            }
            if (valueKey === 'qty') {
              if (findAddon <= -1) {
                const addon = body.addons.findIndex(
                  (adb: any) => adb.id === item.id || adb.id === item.addon_id,
                );
                if (
                  addon > -1 &&
                  Object.keys(body.addons[addon]).includes('discounted_total_price_dict')
                ) {
                  body.addons[addon] = _.omit(body.addons[addon], 'discounted_total_price_dict');
                }
              }
            }
            if (
              findAddon > -1 &&
              _.isEmpty(body.addons[findAddon]?.discounted_list_unit_price_dict)
            ) {
              body.addons[findAddon] = _.omit(
                body.addons[findAddon],
                'discounted_list_unit_price_dict',
              );
            }
            if (findAddon > -1 && _.isEmpty(body.addons[findAddon]?.discounted_total_price_dict)) {
              body.addons[findAddon] = _.omit(
                body.addons[findAddon],
                'discounted_total_price_dict',
              );
            }
          }
        } else if (Object.keys(prevPostBody).length) {
          const prevData = prevPostBody?.addons.findIndex(
            (ad: any) => details.addons.rows[index].id === ad.id,
          );
          if (prevData > -1) {
            body.addons.push(prevPostBody.addons[prevData]);
          }
        }
        return null;
      });
    }
    setCalcBody({ ...payloadOb, [productId]: body });
    return body;
  };

  // const calculateDiscount = (
  //   // to be changed
  //   productIndex: number,
  //   tierIndex: number,
  //   rowIndex: number,
  //   value: any,
  //   type: any,
  //   productArray: any,
  //   listUnitPriceData: any,
  // ) => {
  //   let priceDiff =
  //     // eslint-disable-next-line
  //     value == 0
  //       ? 0
  //       : ((Number(listUnitPriceData.value) - Number(value)) * 100) /
  //         Number(listUnitPriceData.value);
  //   if (priceDiff > 100) {
  //     priceDiff = 0;
  //   }
  //   if (isFloat(priceDiff)) {
  //     priceDiff = Number(priceDiff.toFixed(2));
  //   }
  //   const tempArray = JSON.parse(JSON.stringify(productArray));
  //   let qty = 0;
  //   let listUnitPrice = 0;
  //   tempArray.products[productIndex].tier_details[tierIndex].details[type].rows[
  //     rowIndex
  //   ].values.map((item: any) => {
  //     if (item.key === 'qty' && item.value !== null) {
  //       qty = item.value;
  //     }
  //     if (item.key === 'list_unit_price' && item.value !== null) {
  //       listUnitPrice = item.value;
  //     }
  //     if (item.key === 'discount') {
  //       item.value = priceDiff;
  //     }
  //     if (item.key === 'discounted_total_price') {
  //       if (value === '0' || value === '') {
  //         item.value = listUnitPrice * qty;
  //       } else {
  //         item.value = value * qty;
  //       }
  //     }
  //     return null;
  //   });
  //   dispatch(setPriceBookDetails(tempArray));
  // };

  const isFloat = (n: number) => {
    return Number(n) === n && n % 1 !== 0;
  };

  const calCulateTotal = (array: any) => {
    const tempArray = JSON.parse(JSON.stringify(array));
    tempArray.products.map((product: any) => {
      product.total.map((productTotal: any) => {
        if (product.tiers && product.tiers.length > 0) {
          productTotal.value = 0;
          product.tiers.map((tier: any) => {
            const tierIndex = product.tier_details.findIndex(
              (xy: any) => xy.tier_id === tier.tier_id,
            );
            if (tier && tier.details && tier.details.core && tier.details.core.rows) {
              tier.details.core.rows.map((rowData: any, k: number) => {
                const findIndex = rowData.values.findIndex(
                  (rowValue: any) => rowValue.key === productTotal.key,
                );
                if (findIndex > -1 && !rowData.metric && rowData.values[findIndex].value !== null) {
                  if (product.discount) {
                    if (
                      productTotal.key === 'discount' &&
                      product.discount < rowData.values[findIndex].value
                    ) {
                      rowData.values[findIndex].error = true;
                      rowData.values[findIndex].maxDiscount = product.discount;
                      product.tier_details[tierIndex].details.core.rows[k].values[findIndex].error =
                        true;
                      product.tier_details[tierIndex].details.core.rows[k].values[
                        findIndex
                      ].maxDiscount = product.discount;
                    } else {
                      rowData.values[findIndex].error = false;
                      product.tier_details[tierIndex].details.core.rows[k].values[findIndex].error =
                        false;
                    }
                    if (productTotal.key === 'discounted_total_price') {
                      const filerData = rowData.values.filter(
                        (fields: any) => fields.key === 'discount',
                      );
                      if (filerData && filerData.length > 0) {
                        product.tier_details[tierIndex].details.core.rows[k].values[
                          findIndex
                        ].error = filerData[0].error;
                        product.tier_details[tierIndex].details.core.rows[k].values[
                          findIndex
                        ].maxDiscount = filerData[0].maxDiscount;
                      }
                    }
                  }
                  productTotal.value += rowData.values[findIndex].value;
                }
                return null;
              });
              tier.details.addons.rows.map((rowData: any, k: number) => {
                const findIndex = rowData.values?.findIndex(
                  (rowValue: any) => rowValue.key === productTotal.key,
                );
                if (findIndex > -1 && rowData.values[findIndex].value !== null) {
                  if (product.discount) {
                    if (
                      productTotal.key === 'discount' &&
                      product.discount < rowData.values[findIndex].value
                    ) {
                      rowData.values[findIndex].error = true;
                      rowData.values[findIndex].maxDiscount = product.discount;
                      product.tier_details[tierIndex].details.addons.rows[k].values[
                        findIndex
                      ].error = true;
                      product.tier_details[tierIndex].details.addons.rows[k].values[
                        findIndex
                      ].maxDiscount = product.discount;
                    } else {
                      rowData.values[findIndex].error = false;
                      product.tier_details[tierIndex].details.addons.rows[k].values[
                        findIndex
                      ].error = false;
                    }
                    if (productTotal.key === 'discounted_total_price') {
                      const filerData = rowData.values.filter(
                        (fields: any) => fields.key === 'discount',
                      );
                      if (filerData && filerData.length > 0) {
                        product.tier_details[tierIndex].details.addons.rows[k].values[
                          findIndex
                        ].error = filerData[0].error;
                        product.tier_details[tierIndex].details.addons.rows[k].values[
                          findIndex
                        ].maxDiscount = filerData[0].maxDiscount;
                      }
                    }
                  }
                  productTotal.value += rowData.values[findIndex].value;
                }
                return null;
              });
            }

            return null;
          });
        }
        return null;
      });
      if (product.total) {
        const listTotalIndex = product.total.findIndex((x: any) => x.key === 'list_total_price');
        const discountIndex = product.total.findIndex((x: any) => x.key === 'discount');
        const discountTotalIndex = product.total.findIndex(
          (x: any) => x.key === 'discounted_total_price',
        );
        if (
          listTotalIndex > -1 &&
          discountIndex > -1 &&
          discountTotalIndex > -1 &&
          product.total[listTotalIndex].value != null &&
          product.total[discountTotalIndex].value != null
        ) {
          const discountDiff =
            product.total[listTotalIndex].value - product.total[discountTotalIndex].value;
          // if (discountDiff < 0) {
          //   discountDiff = 0;
          // }
          let totalDiscount = (discountDiff * 100) / product.total[listTotalIndex].value;
          if (totalDiscount === 100) {
            totalDiscount = 0;
          }
          if (isFloat(totalDiscount)) {
            totalDiscount = Number(totalDiscount.toFixed(2));
          }
          product.total[discountIndex].value = totalDiscount;
          if (totalDiscount > product.discount) {
            product.total[discountIndex].error = true;
          } else {
            product.total[discountIndex].error = false;
          }
        }
      }
      if (product?.tiers?.length > 0) {
        product.tiers = product.tier_details.filter((tier: any) => tier.checked);
      }
      return null;
    });
    if (JSON.stringify(priceBookDetails) !== JSON.stringify(tempArray)) {
      dispatch(setPriceBookDetails(tempArray));
    }
  };

  const filterColumncell = (coreData: any, showError = true) => {
    if (['qty', 'list_unit_price', 'discounted_unit_price'].includes(coreData.key)) return null;
    return columnsCell(coreData, showError);
  };

  const columnsCell = (coreData: any, showError = false) => {
    // eslint-disable-next-line no-nested-ternary
    const val = _.isObject(coreData.value)
      ? Object.keys(coreData.value).length
        ? Object.values(coreData.value)[0]
        : null
      : coreData.value;
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: componentStyle.tooltipStyle,
          },
        }}
        title={
          coreData.error && showError ? (
            <FormattedMessage
              id="maxEscalateDiscount"
              values={{ discount: coreData.maxDiscount }}
            />
          ) : (
            ''
          )
        }>
        <Box sx={componentStyle.cellContainer}>
          <Box sx={componentStyle.cellText(coreData.error)}>
            {(() => {
              if (typeof val === 'number') {
                return Number.isInteger(val) ? val : val.toFixed(2);
              }
              return 0;
            })()}
          </Box>
          {showError && coreData.error ? <InfoIcon sx={componentStyle.infoIcon} /> : null}
        </Box>
      </Tooltip>
    );
  };

  const saveComments = async (comment: string) => {
    try {
      setLoader(true);
      const response = await PricingCalculatorClient.addComment(selectedQuoteDetails.id, {
        comment,
      });
      if (response.message === 'success') {
        setOpenNote(false);
        setLoader(false);
        setSnackBarValues({
          display: true,
          type: 'success',
          message: intl.formatMessage({ id: selectedOption }),
        });
        setSelectedOption('');
      }
    } catch (e) {
      setLoader(false);
      console.error(e);
    }
  };

  // const forwardToDealDesk = async () => {
  //   try {
  //     setForwardToDealDeskLoader(true);
  //     const response = await PricingCalculatorClient.forwardToDealDesk(selectedQuoteDetails.id);
  //     if (response.message === 'success') {
  //       setSelectedOption('quoteForwarded');
  //       setOpenNote(true);
  //       const tempObject = JSON.parse(JSON.stringify(selectedQuoteDetails));
  //       dispatch(
  //         setSelectedQuoteDetails({
  //           ...tempObject,
  //           assigned_to_id: response.data?.assigned_to ?? '',
  //           status: QuoteStatus.FORWARD_TO_DD,
  //         }),
  //       );
  //     }
  //     setForwardToDealDeskLoader(false);
  //   } catch (e) {
  //     setForwardToDealDeskLoader(false);
  //     console.error(e);
  //   }
  // };

  const resendToAE = async () => {
    try {
      setResendToAeLoader(true);
      const response = await PricingCalculatorClient.resendToAe(selectedQuoteDetails.id);
      if (response.message === 'success') {
        setSelectedOption('quoteResent');
        setOpenNote(true);
        const tempObject = JSON.parse(JSON.stringify(selectedQuoteDetails));
        dispatch(
          setSelectedQuoteDetails({
            ...tempObject,
            assigned_to_id: response.data?.assigned_to ?? '',
            status: QuoteStatus.DRAFT,
          }),
        );
      }
      setResendToAeLoader(false);
    } catch (e) {
      setResendToAeLoader(false);
      console.error(e);
    }
  };

  const selfApproval = async (status: string) => {
    try {
      setEscalateLoader(true);
      const response = await PricingCalculatorClient.selfApproval(selectedQuoteDetails.id, status);
      if (response.message === 'success') {
        const tempObject = JSON.parse(JSON.stringify(selectedQuoteDetails));
        dispatch(
          setSelectedQuoteDetails({
            ...tempObject,
            status,
          }),
        );
      }
      setEscalateLoader(false);
    } catch (e) {
      setEscalateLoader(false);
      console.error(e);
    }
  };

  const escalateToManager = async (status: string) => {
    try {
      const body = { status } as any;
      body.details = [];
      selectedQuoteDetails.quote_details.map((item: any) => {
        const product = { product_id: item.product_id } as any;
        item.tiers.map((tier: any) => {
          const filterDiscount = tier.total.filter((column: any) => column.key === 'discount');
          if (filterDiscount && filterDiscount.length > 0) {
            product.discount = filterDiscount[0].value.toString();
          }
          return null;
        });
        body.details.push(product);
        return null;
      });
      setEscalateLoader(true);
      const response = await PricingCalculatorClient.escalateForApproval(
        selectedQuoteDetails.id,
        body,
      );
      if (response.message === 'success') {
        setOpenNote(true);
        const tempObject = JSON.parse(JSON.stringify(selectedQuoteDetails));
        dispatch(
          setSelectedQuoteDetails({
            ...tempObject,
            assigned_to_id: response.data?.assigned_to ?? '',
            status,
          }),
        );
        setEscalateLoader(false);
      }
    } catch (e) {
      setEscalateLoader(false);
      console.error(e);
    }
  };

  const getPlaceHolder = (key: string, isDisabledColumn: boolean) => {
    if (key === 'discounted_unit_price' && isDisabledColumn) return '';
    if (key === 'discounted_unit_price' || key === 'discounted_total_price')
      return intl.formatMessage({
        id: 'price',
      });

    if (key === 'qty')
      return intl.formatMessage({
        id: 'quantity',
      });
    return '';
  };

  const getButtonLabel = () => {
    if (isApprovalRequired) {
      return intl.formatMessage({ id: 'escalateForApproval' });
    }
    return null;
    // return intl.formatMessage({ id: 'finalise' });
  };

  // const updateContractSpecificData = (configSpecificData: any) => {
  //   setContractSpecificLoader(true);
  //   ContractClient.saveContractSpecificConfig(selectedQuoteDetails.id, configSpecificData)
  //     .then(() => {
  //       setConfigPopup(false);
  //       setSnackBarValues({
  //         display: true,
  //         type: 'success',
  //         message: 'Config updated successfully',
  //       });
  //       setContractSpecificLoader(false);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setContractSpecificLoader(false);
  //     });
  // };
  const getQuoteVersions = async () => {
    if (selectedOpportunity.id) {
      try {
        const response = await PricingCalculatorClient.getQuotesForOpportunity(
          selectedPricingBook.id,
          selectedOpportunity.id,
        );
        if (response.message === 'success') {
          dispatch(setQuoteVersions(response.data));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openDeleteModal = (val: any): any => {
    setDeleteProdId(val?.id);
    setDeleteProdName(val?.name);
    setShowDeleteConfirmDialog(true);
  };
  const onDeleteProduct = () => {
    setDeleteLoader(true);

    PricingCalculatorClient.deleteQuote(deleteProdId)
      .then(({ data }) => {
        // dispatch(setSelectedPricingBook({}));
        dispatch(setSelectedQuote({}));
        // dispatch(setQuoteVersions([]));
        dispatch(setSelectedQuoteDetails({}));
        setShowDeleteConfirmDialog(false);
        setDeleteLoader(false);
        getQuoteVersions();
        setQuoteName('');
        setSnackBarValues({
          display: true,
          type: 'success',
          message: data,
        });
        setTimeout(() => {
          dispatch(setSelectedPricingBook({}));
        }, 1000);
      })
      .catch((err) => {
        setDeleteLoader(false);
        setShowDeleteConfirmDialog(false);
        console.error(err);
      });
  };
  const headerCurrencyFormat = (item: any) => {
    if (['qty', 'discounting'].indexOf(item.key) === -1) {
      const curr = isCurrencyCodeExists(selectedOpportunity.currency);
      if (curr !== undefined) {
        return `${item.name}(${curr})`;
      }
      return `${item.name}`;
    }
    return `${item.name}`;
  };

  const handleDealTermChange = (e: SelectChangeEvent<unknown> | any, idx: number, val: string) => {
    const dealTermValuess = [...dealTermValues];

    if (val === 'date-picker') {
      dealTermValuess[idx].value = e;
      dealTermValuess[idx].deal_term_option_id = null;
    } else {
      const { value } = e.target;

      if (val === 'checkbox') {
        dealTermValuess[idx].value = value;
        dealTermValuess[idx].deal_term_option_id = null;
      } else if (val === 'text-field') {
        if (value.length < 25) {
          dealTermValuess[idx].value = value;
          dealTermValuess[idx].deal_term_option_id = null;
        }
      } else {
        dealTermValuess[idx].deal_term_option_id = value;
        dealTermValuess[idx].value = null;
      }
    }
    setDealTermValues(dealTermValuess);
  };

  const handleDealTermCheckBox = (e: SelectChangeEvent<unknown> | any) => {
    const selectedDealTermCheckboxArrr = [...selectedDealTermCheckboxArr];

    if (selectedDealTermCheckboxArr.includes(e.target.name)) {
      setSelectedDealTermCheckboxArr(
        selectedDealTermCheckboxArrr.filter((i: string) => i !== e.target.name),
      );
    } else {
      selectedDealTermCheckboxArrr.push(e.target.name);
      setSelectedDealTermCheckboxArr(selectedDealTermCheckboxArrr);
    }
  };

  const booleanArray: any[] = [
    { id: 'true', label: 'true', value: 'true' },
    { id: 'false', label: 'false', value: 'false' },
  ];

  const handleValidation = () => {
    let qtyError = false;
    let selectedProductsLength = 0;
    const filtered = dealTermValues.filter((i: any) => i.is_required === true);

    const failedDealTermError = filtered
      .filter(
        (item: any) =>
          ((item.component === 'checkbox' ||
            item.component === 'text-field' ||
            item.component === 'date-picker') &&
            (item.value === null || item.value === '')) ||
          ((item.component === 'select' || item.component === 'radio') &&
            (item.deal_term_option_id === null || item.deal_term_option_id === '')),
      )
      .map((item: any) => item.deal_term_id);

    setRequiredNotFilledDealTerms(failedDealTermError);

    const isError = !filtered.some((item: any) =>
      item.component === 'checkbox' ||
      item.component === 'text-field' ||
      item.component === 'date-picker'
        ? item.value === null || item.value === ''
        : item.deal_term_option_id === null || item.deal_term_option_id === '',
    );
    const products = JSON.parse(JSON.stringify(selectedPricingProducts));
    // eslint-disable-next-line array-callback-return
    products.forEach((item: any) => {
      item.tier_details.forEach((i: any) => {
        if (i.details) {
          i.details.core.rows.forEach((coree: any) => {
            const foundValue = coree.values.find((single: any) => single.key === 'qty');
            if (
              foundValue &&
              (foundValue.value === null || foundValue.value === 0 || foundValue.value === '0')
            ) {
              qtyError = true;
            }
          });
        }
      });
    });
    setIsCorQtyError(qtyError);

    if (Object.keys(priceBookDetails).length > 0) {
      const selectedProducts = priceBookDetails.products.filter(
        (product: any) => product.checked && product.checked === true,
      );
      selectedProductsLength = selectedProducts.length;
    } else {
      selectedProductsLength = 0;
    }

    if (selectedProductsLength > 0) {
      setIsSelectedProduct(true);
    } else {
      setIsSelectedProduct(false);
    }

    if (isError) {
      if (!qtyError && selectedProductsLength > 0) {
        saveQuote();
      }
      setIsDealTermValidationError(false);
    } else {
      setIsDealTermValidationError(true);
    }
  };

  useEffect(() => {
    if (
      dealTermValues.length > 0 &&
      selectedPricingProducts.length > 0 &&
      filteredDealTermSchema &&
      Object.keys(filteredDealTermSchema).length > 0 &&
      filteredDealTermSchema.fields.length > 0 &&
      !_.isEqual(prevDealTermValuesRef.current, dealTermValues)
    ) {
      prevDealTermValuesRef.current = dealTermValues;
      const filtered = dealTermValues.filter((item: any) => item.discount_policy_id !== null);
      if (filtered.length > 0) {
        const ar: string[] = [];
        for (let i = 0; i < filtered.length; i += 1) {
          ar.push(filtered[i].deal_term_id);
        }
        const a = [...selectedDealTermCheckboxArr];
        const finalArr = a.concat(ar);
        const uniqueArray = Array.from(new Set(finalArr));
        setSelectedDealTermCheckboxArr(uniqueArray);
      }
    }
  }, [dealTermValues, filteredDealTermSchema, selectedPricingProducts]);
  const handleIsCheckboxDisabled = (
    dealTermId: string,
    component: string,
    dealTermOptionId: string,
    value: string,
  ) => {
    let r = true;
    if (selectedQuoteDetails.status === 'escalate_for_approval') {
      r = true;
    } else if (component === 'checkbox') {
      const val = dealTermDiscountsAll.find(
        (i: any) => i.deal_term_id === dealTermId && i.value === value,
      );
      if (val) {
        r = false;
      }
    } else {
      const val = dealTermDiscountsAll.find(
        (i: any) => i.deal_term_id === dealTermId && i.deal_term_option_id === dealTermOptionId,
      );
      if (val) {
        r = false;
      }
    }
    return r;
  };
  return (
    <Box>
      <Box sx={componentStyle.downloadBtnContainer}>
        <Box>
          {checkSaveQuoteCondition() ? (
            <SaveInput
              value={quoteName}
              showProgress={saveLoader}
              onTextChange={(event) => {
                if (event.target.value.length < 50) {
                  setQuoteName(event.target.value);
                }
              }}
              onClick={handleValidation}
              placeholder="quote_name"
              btnText="save_quote"
              pricingCalculator
              setIsNewQuoteError={setIsNewQuoteError}
              id="save-quote-box"
              isErrorInCalculationApi={isErrorInCalculationApi}
            />
          ) : null}
        </Box>

        {Object.keys(selectedQuoteDetails).length > 0 && (
          <>
            <Button
              size="small"
              onClick={() => openDeleteModal(selectedQuoteDetails)}
              sx={componentStyle.deleteBtn}>
              <FormattedMessage id="delete" />
            </Button>
            <Button
              size="small"
              onClick={() => handleDownload(selectedQuoteDetails?.id, selectedQuoteDetails?.name)}
              sx={componentStyle.downloadBtn}>
              <FormattedMessage id="download" />
            </Button>
          </>
        )}
      </Box>
      {!IsselectedProduct && (
        <Box sx={{ color: 'red', fontSize: 15, pb: 1 }}>
          <Typography>Please select atleast 1 product to save Quote.</Typography>
        </Box>
      )}
      {isNewQuoteError && (
        <Box sx={{ color: 'red', fontSize: 15, pb: 1 }}>
          <Typography>Please select opportunity before creating new quote.</Typography>
        </Box>
      )}
      {isDealTermValidationError && (
        <Box sx={{ color: 'red', fontSize: 15, pb: 1 }}>
          <Typography>Please fill all mandatory deal terms.</Typography>
        </Box>
      )}
      {isCoreQtyError && (
        <Box sx={{ color: 'red', fontSize: 15, pb: 1 }}>
          <Typography>Please enter quantity before saving quote.</Typography>
        </Box>
      )}

      {hierachyDiscounts === undefined || hierachyDiscounts.length === 0 ? (
        <Box sx={{ color: 'red', fontSize: 15, pb: 1 }}>
          <Typography>*No discounting policy assigned to this user</Typography>
        </Box>
      ) : (
        ''
      )}
      <Table sx={componentStyle.tableContainer}>
        <TableHead>
          <TableRow sx={componentStyle.tableHeaderRow}>
            <TableCell sx={componentStyle.tableSpace}>
              {intl.formatMessage({ id: 'granular_quote' })}
            </TableCell>
            {pricingTableColumn.map((item, index) => {
              return (
                <TableCell
                  sx={[
                    componentStyle.tableCell,
                    index === pricingTableColumn.length - 1 ? componentStyle.lastItem : {},
                  ]}
                  key={item.key}>
                  {headerCurrencyFormat(item)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedPricingProducts.length > 0 &&
            selectedPricingProducts.map((item: any) => {
              return (
                <React.Fragment key={item.product_id}>
                  <TableRow sx={componentStyle.productRow}>
                    <TableCell colSpan={7} sx={componentStyle.productLabel}>
                      {item.product_name}
                      <Box
                        sx={{ color: 'red', fontSize: 15, pb: 1, display: 'inline-block', ml: 2 }}>
                        <Typography sx={{ fontSize: '14px' }}>
                          {(() => {
                            if (!item.discount) {
                              return '';
                            }
                            if (item?.discount) {
                              return ` *Maximum discount allowed for this user: ${item?.discount}%`;
                            }
                            return ` *No discount policy available for this product`;
                          })()}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {item.tiers &&
                    item.tiers.map((tier: any) => {
                      return (
                        <React.Fragment key={tier.tier_id}>
                          {item.tier_details.length === 1 &&
                          item.tier_details[0].tier_name === 'Default_Tier' ? null : (
                            <TableRow>
                              <TableCell colSpan={1} sx={componentStyle.tierLabel}>
                                <Box sx={componentStyle.displayFlex}>
                                  <Box>{tier.tier_name}</Box>
                                </Box>
                              </TableCell>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                          {tier.details
                            ? tier.details.core.rows.map((core: any, coreRowIndex: number) => {
                                return (
                                  <TableRow key={core.output_column.key}>
                                    <TableCell
                                      sx={[
                                        componentStyle.tierSubData,
                                        componentStyle.tierSubPaddingLeft,
                                      ]}>
                                      <Box sx={componentStyle.displayFlex}>
                                        {/* <Box>{core.metric ? core?.metric_name : ''}</Box> */}
                                        <Box sx={componentStyle.rightContainer}>
                                          {item.pricing_metric_name
                                            ? item.pricing_metric_name
                                            : core.output_column?.name}
                                        </Box>
                                      </Box>
                                    </TableCell>
                                    {core.values &&
                                      core.values.map((coreData: any, colIndex: number) => {
                                        if (
                                          changedCoreRowIndex !== coreRowIndex ||
                                          (changedCoreRowIndex === coreRowIndex &&
                                            coreData.key === 'qty')
                                        ) {
                                          return (
                                            <TableCell
                                              key={coreData.key}
                                              sx={[
                                                componentStyle.tierSubData,
                                                componentStyle.textCenter,
                                                // coreData.error
                                                //   ? { ...componentStyle.alertMessage }
                                                //   : {},
                                              ]}>
                                              {coreData.key === 'qty' ? (
                                                <IconButton
                                                  aria-label="reset"
                                                  sx={componentStyle.resetIconPosition}
                                                  onClick={() =>
                                                    onResetRow(
                                                      priceBookDetails,
                                                      item.product_id,
                                                      tier.tier_id,
                                                      coreRowIndex,
                                                      core,
                                                      'core',
                                                      calcBody,
                                                    )
                                                  }>
                                                  <ReplayIcon sx={{ width: '0.85em' }} />
                                                </IconButton>
                                              ) : null}
                                              {coreData.key === 'qty' ||
                                              coreData.key === 'discounted_unit_price' ||
                                              coreData.key === 'discounted_total_price' ? (
                                                <Tooltip
                                                  title={
                                                    coreData.key === 'qty'
                                                      ? core.metric_column.name
                                                      : ''
                                                  }>
                                                  <TextField
                                                    type="number"
                                                    disabled={
                                                      coreData.key === 'discounted_unit_price' &&
                                                      core.unit_columns?.length === 0
                                                    }
                                                    value={
                                                      // eslint-disable-next-line no-nested-ternary
                                                      _.isObject(coreData.value)
                                                        ? Object.keys(coreData.value).length
                                                          ? Object.values(coreData.value)[0]
                                                          : 0
                                                        : coreData.value
                                                        ? coreData.value
                                                        : 0
                                                    }
                                                    onChange={(e) => {
                                                      onInputChange(
                                                        priceBookDetails,
                                                        item.product_id,
                                                        tier.tier_id,
                                                        coreRowIndex,
                                                        colIndex,
                                                        e.target.value,
                                                        'core',
                                                        coreData.key,
                                                        calcBody,
                                                      );
                                                    }}
                                                    placeholder={getPlaceHolder(
                                                      coreData.key,
                                                      core.unit_columns?.length === 0,
                                                    )}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: 0,
                                                        style: {
                                                          textAlign: 'center',
                                                          padding: '0px',
                                                        },
                                                        step: 0.1,
                                                        readOnly:
                                                          (changedCoreRowIndex !== null &&
                                                            changedCoreRowIndex !== coreRowIndex) ||
                                                          (coreData.key ===
                                                            'discounted_unit_price' &&
                                                            !item.discount),
                                                      },
                                                    }}
                                                    sx={componentStyle.inputField}
                                                  />
                                                </Tooltip>
                                              ) : (
                                                columnsCell(coreData, false)
                                              )}
                                            </TableCell>
                                          );
                                        }
                                        return null;
                                      })}
                                    {changedCoreRowIndex !== null &&
                                    changedCoreRowIndex === coreRowIndex ? (
                                      <TableCell
                                        sx={componentStyle.centerLoader}
                                        key={uuidv4()}
                                        colSpan={5}>
                                        <CircularProgress size={20} />
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                );
                              })
                            : null}
                          {tier.details
                            ? tier.details.addons.rows.map((adb: any, addonRowIndex: number) => {
                                const qtyValue = adb.values.find(
                                  (quantity: any) => quantity.key === 'qty',
                                )?.value;
                                return (
                                  <TableRow
                                    key={
                                      adb.output_column
                                        ? adb.output_column.key + adb.addon_id
                                        : adb.addon_id + adb.name || adb.id + adb.name
                                    }>
                                    <TableCell
                                      colSpan={adb.customAddonMetric ? 7 : 1}
                                      sx={componentStyle.tierLabel}>
                                      {adb.addonRow ? (
                                        <Box sx={componentStyle.displayFlex}>
                                          {/* <Box>{core.metric ? core?.metric_name : ''}</Box> */}
                                          <Box sx={componentStyle.rightContainer}>
                                            {adb.output_column.name}
                                          </Box>
                                        </Box>
                                      ) : (
                                        adb.name
                                      )}
                                    </TableCell>
                                    {adb.values &&
                                      adb.values.map((coreData: any, colIndex: number) => {
                                        if (
                                          changedAddonsRowIndex !== addonRowIndex ||
                                          (changedAddonsRowIndex === addonRowIndex &&
                                            coreData.key === 'qty')
                                        ) {
                                          return (
                                            <TableCell
                                              key={coreData.key}
                                              sx={[
                                                componentStyle.tierSubData,
                                                componentStyle.textCenter,
                                                // coreData.error
                                                //   ? { ...componentStyle.alertMessage }
                                                //   : {},
                                              ]}>
                                              {coreData.key === 'qty' ? (
                                                <IconButton
                                                  aria-label="reset"
                                                  sx={componentStyle.resetIconPosition}
                                                  onClick={() =>
                                                    onResetRow(
                                                      priceBookDetails,
                                                      item.product_id,
                                                      tier.tier_id,
                                                      addonRowIndex,
                                                      adb,
                                                      'addons',
                                                      calcBody,
                                                    )
                                                  }>
                                                  <ReplayIcon sx={{ width: '0.85em' }} />
                                                </IconButton>
                                              ) : null}
                                              {coreData.key === 'qty' ||
                                              coreData.key === 'discounted_unit_price' ||
                                              coreData.key === 'discounted_total_price' ? (
                                                <Tooltip
                                                  title={
                                                    coreData.key === 'qty' && adb.metric_column
                                                      ? adb.metric_column.name
                                                      : ''
                                                  }>
                                                  {!adb.sell_multiple &&
                                                  !adb.metric_column &&
                                                  coreData.key === 'qty' ? (
                                                    <HandleSelect
                                                      val={coreData.value}
                                                      callback={() => {
                                                        onInputChange(
                                                          priceBookDetails,
                                                          item.product_id,
                                                          tier.tier_id,
                                                          addonRowIndex,
                                                          colIndex,
                                                          '1',
                                                          'addons',
                                                          coreData.key,
                                                          calcBody,
                                                          'withoutDebounce',
                                                        );
                                                      }}>
                                                      <Select
                                                        displayEmpty
                                                        value={coreData.value ?? ''}
                                                        onChange={(e) => {
                                                          onInputChange(
                                                            priceBookDetails,
                                                            item.product_id,
                                                            tier.tier_id,
                                                            addonRowIndex,
                                                            colIndex,
                                                            e.target.value,
                                                            'addons',
                                                            coreData.key,
                                                            calcBody,
                                                          );
                                                        }}
                                                        inputProps={{
                                                          style: {
                                                            textAlign: 'center',
                                                            padding: '0px',
                                                          },
                                                          readOnly:
                                                            (changedAddonsRowIndex !== null &&
                                                              changedAddonsRowIndex !==
                                                                addonRowIndex) ||
                                                            (coreData.key ===
                                                              'discounted_unit_price' &&
                                                              !item.discount),
                                                        }}
                                                        sx={{
                                                          ...componentStyle.inputField,
                                                          '& .MuiSelect-select': {
                                                            textAlign: 'center',
                                                          },
                                                          width: '135px',
                                                          height: '30px',
                                                        }}>
                                                        <MenuItem key={0} value="0">
                                                          0
                                                        </MenuItem>
                                                        <MenuItem key={1} value="1">
                                                          1
                                                        </MenuItem>
                                                      </Select>
                                                    </HandleSelect>
                                                  ) : (
                                                    <HandleTextBox
                                                      val={
                                                        coreData.key === 'qty'
                                                          ? coreData.value
                                                          : 'false'
                                                      }
                                                      callback={() => {
                                                        onInputChange(
                                                          priceBookDetails,
                                                          item.product_id,
                                                          tier.tier_id,
                                                          addonRowIndex,
                                                          colIndex,
                                                          '1',
                                                          'addons',
                                                          coreData.key,
                                                          calcBody,
                                                          'withoutDebounce',
                                                        );
                                                      }}>
                                                      <TextField
                                                        type="number"
                                                        value={
                                                          // eslint-disable-next-line no-nested-ternary
                                                          _.isObject(coreData.value)
                                                            ? Object.keys(coreData.value).length
                                                              ? Object.values(coreData.value)[0]
                                                              : 0
                                                            : coreData.value
                                                            ? coreData.value
                                                            : 0
                                                        }
                                                        disabled={
                                                          (coreData.key ===
                                                            'discounted_total_price' &&
                                                            qtyValue === 0) ||
                                                          (coreData.key ===
                                                            'discounted_unit_price' &&
                                                            !adb.metric_column)
                                                        }
                                                        placeholder={getPlaceHolder(
                                                          coreData.key,
                                                          !adb.metric_column,
                                                        )}
                                                        onChange={(e) => {
                                                          onInputChange(
                                                            priceBookDetails,
                                                            item.product_id,
                                                            tier.tier_id,
                                                            addonRowIndex,
                                                            colIndex,
                                                            e.target.value,
                                                            'addons',
                                                            coreData.key,
                                                            calcBody,
                                                          );
                                                        }}
                                                        InputProps={{
                                                          inputProps: {
                                                            min: 0,
                                                            style: {
                                                              textAlign: 'center',
                                                              padding: '0px',
                                                            },
                                                            step: 0.1,
                                                            readOnly:
                                                              (changedAddonsRowIndex !== null &&
                                                                changedAddonsRowIndex !==
                                                                  addonRowIndex) ||
                                                              (coreData.key ===
                                                                'discounted_unit_price' &&
                                                                !item.discount),
                                                          },
                                                        }}
                                                        sx={componentStyle.inputField}
                                                      />
                                                    </HandleTextBox>
                                                  )}
                                                </Tooltip>
                                              ) : (
                                                columnsCell(coreData, false)
                                              )}
                                            </TableCell>
                                          );
                                        }
                                        return null;
                                      })}
                                    {changedAddonsRowIndex !== null &&
                                    changedAddonsRowIndex === addonRowIndex ? (
                                      <TableCell
                                        sx={componentStyle.centerLoader}
                                        key={uuidv4()}
                                        colSpan={5}>
                                        <CircularProgress size={24} />
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                );
                              })
                            : null}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
          <TableRow sx={componentStyle.tableHeaderRow}>
            <TableCell colSpan={7} sx={componentStyle.productLabel}>
              {intl.formatMessage({ id: 'total' })}
            </TableCell>
          </TableRow>
          {selectedPricingProducts.length > 0 &&
            selectedPricingProducts.map((item: any) => {
              return (
                <React.Fragment key={item.product_id}>
                  <TableRow sx={componentStyle.productRow}>
                    <TableCell sx={componentStyle.productLabel}>{item.product_name}</TableCell>
                    {item.total &&
                      item.total.map((totalData: any) => {
                        return (
                          <TableCell
                            sx={[
                              componentStyle.tierSubData,
                              componentStyle.textCenter,
                              totalData.error ? { ...componentStyle.alertMessage } : {},
                            ]}
                            key={totalData.key}>
                            {filterColumncell({ ...totalData, maxDiscount: item.discount })}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </React.Fragment>
              );
            })}
          {selectedPricingProducts.length > 0 && (
            <TableRow sx={componentStyle.productRow}>
              <TableCell sx={componentStyle.productLabel}>
                {intl.formatMessage({ id: 'grandTotal' })}
              </TableCell>
              {grandTotal.length &&
                grandTotal.map((totalData: any) => {
                  return (
                    <TableCell sx={[componentStyle.tierSubData, componentStyle.textCenter]}>
                      {filterColumncell(totalData, false)}
                    </TableCell>
                  );
                })}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {selectedPricingProducts.length > 0 && (
        <Typography sx={{ color: 'red', marginY: '10px' }}>
          It includes {totalDealTermDiscountedVal} % deal term discount
        </Typography>
      )}
      {filteredDealTermSchema &&
      dealTermValues.length > 0 &&
      Object.keys(filteredDealTermSchema).length > 0 &&
      filteredDealTermSchema.fields.length > 0 &&
      selectedPricingProducts.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', margin: '20px' }}>
          {filteredDealTermSchema.fields.map((item: any, index: number) => {
            let filtered: any = [];
            if (item?.options?.length > 0) {
              filtered = item.options.filter((i: any) => i.is_active === true);
            }
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  marginBottom: '20px',
                }}>
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <Typography className="fieldHeader">{item.label}</Typography>
                  {item.is_required && <span style={{ color: 'red' }}> *</span>}
                </Box>
                {item.component === 'text-field' ? (
                  <Box display="flex" gap="20px">
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="text-field"
                        value={item.component === 'text-field' ? dealTermValues[index]?.value : ''}
                        placeholder={item.label}
                        name={dealTermValues[index]?.deal_term_id}
                        onChange={(e) => handleDealTermChange(e, index, item.component)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormControlLabel control={<Checkbox disabled />} label="Add discount" />
                    </FormControl>
                  </Box>
                ) : item.component === 'date-picker' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'space-between',
                    }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={<FormattedMessage id="selectDate" />}
                        value={dayjs(dealTermValues[index]?.value)}
                        disablePast
                        format="MM/DD/YYYY"
                        onChange={(e) => handleDealTermChange(e, index, item.component)}
                        slotProps={{
                          textField: {
                            error: false,
                            fullWidth: true,
                            // sx: {
                            //   width: '80%',
                            // },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="include_deal_term_id"
                            disabled
                            name={dealTermValues[index]?.deal_term_id}
                          />
                        }
                        label="Add discount"
                      />
                    </FormControl>
                  </Box>
                ) : (
                  <Box display="flex" gap="20px">
                    <FormControl fullWidth>
                      <InputLabel id={`select-label${index}`}>{item.label}</InputLabel>
                      <Select
                        labelId={`select-label${index}`}
                        label={item.label}
                        id="demo-simple-select"
                        value={
                          item.component === 'checkbox'
                            ? dealTermValues[index]?.value
                            : dealTermValues[index]?.deal_term_option_id
                        }
                        placeholder={item.label}
                        name={dealTermValues[index]?.deal_term_id}
                        onChange={(e) => handleDealTermChange(e, index, item.component)}>
                        {item.component === 'checkbox'
                          ? booleanArray.map((i: any) => (
                              <MenuItem value={i.value}>{i.value}</MenuItem>
                            ))
                          : filtered.map((i: any) => <MenuItem value={i.id}>{i.label}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="include_deal_term_id"
                            name={dealTermValues[index]?.deal_term_id}
                            checked={selectedDealTermCheckboxArr.includes(
                              dealTermValues[index]?.deal_term_id,
                            )}
                            onChange={(e) => handleDealTermCheckBox(e)}
                            disabled={handleIsCheckboxDisabled(
                              dealTermValues[index]?.deal_term_id,
                              dealTermValues[index]?.component,
                              dealTermValues[index]?.deal_term_option_id,
                              dealTermValues[index]?.value,
                            )}
                          />
                        }
                        label="Add discount"
                      />
                    </FormControl>
                  </Box>
                )}
                {requiredNotFilledDealTerms.includes(item.id) && (
                  <Typography sx={{ fontSize: '14px', color: 'red' }}>Required</Typography>
                )}
              </Box>
            );
          })}

          {/* <Button
            startIcon={<SettingsIcon />}
            onClick={() => openConfigPopup()}
            sx={[commonStyle.customButton('#4168E1', null, '4px', null), componentStyle.configBtn]}>
            {intl.formatMessage({ id: 'contractSpecificConfig' })}
          </Button> */}
        </Box>
      ) : null}

      {selectedQuoteDetails.assigned_to_id === userId &&
        selectedQuoteDetails.status === QuoteStatus.ESCALATE_FOR_APPROVAL && (
          <Box sx={[componentStyle.policyContainer, componentStyle.boxMargin]}>
            <Button
              onClick={() => {
                setSelectedOption('quoteApproved');
                escalateToManager(QuoteStatus.APPROVED);
              }}
              sx={[
                commonStyle.customButton('#4168E1', null, '4px', null),
                componentStyle.btnRight,
              ]}>
              {escalateLoader ? (
                <CircularProgress sx={componentStyle.loaderStyle} size={24} />
              ) : (
                intl.formatMessage({ id: 'approve' })
              )}
            </Button>
            <Button
              onClick={resendToAE}
              sx={[
                commonStyle.customButton('#865DDA', null, '4px', null),
                componentStyle.btnRight,
              ]}>
              {resendToAeLoader ? (
                <CircularProgress sx={componentStyle.loaderStyle} size={24} />
              ) : (
                intl.formatMessage({ id: 'resend_to_ae' })
              )}
            </Button>
          </Box>
        )}

      {selectedQuoteDetails.assigned_to_id === userId &&
        selectedQuoteDetails.status === QuoteStatus.DRAFT && (
          <Box sx={[componentStyle.policyContainer, componentStyle.boxMargin]}>
            {/* <Button
              onClick={() => {
                setSelectedOption('quoteEscalated');
                escalateToManager(QuoteStatus.ESCALATE_FOR_APPROVAL);
              }}
              sx={[
                commonStyle.customButton('#4168E1', null, '4px', '12px 0px'),
                componentStyle.btnRight,
              ]}>
              <ArrowUpwardIcon />
            </Button> */}

            {isApprovalRequired && (
              <Button
                onClick={() => {
                  setSelectedOption('quoteEscalated');
                  if (isApprovalRequired) {
                    escalateToManager(QuoteStatus.ESCALATE_FOR_APPROVAL);
                  } else {
                    selfApproval(QuoteStatus.APPROVED);
                  }
                }}
                disabled={disableEscalation}
                sx={[
                  commonStyle.customButton('#4168E1', null, '4px', null),
                  componentStyle.btnRight,
                ]}>
                {escalateLoader ? (
                  <CircularProgress sx={componentStyle.loaderStyle} size={24} />
                ) : (
                  getButtonLabel()
                )}
              </Button>
            )}
            {/* <Button
              onClick={forwardToDealDesk}
              sx={[
                commonStyle.customButton('#865DDA', null, '4px', '12px 0px'),
                componentStyle.btnRight,
              ]}>
              <ArrowForwardIcon />
            </Button>
            <Button
              onClick={forwardToDealDesk}
              sx={[
                commonStyle.customButton('#865DDA', null, '4px', null),
                componentStyle.btnRight,
              ]}>
              {forwardToDealDeskLoader ? (
                <CircularProgress sx={componentStyle.loaderStyle} size={24} />
              ) : (
                intl.formatMessage({ id: 'forwardDealDesk' })
              )}
            </Button> */}
          </Box>
        )}

      {(selectedQuoteDetails.status === QuoteStatus.FORWARD_TO_DD ||
        selectedQuoteDetails.status === QuoteStatus.ESCALATE_FOR_APPROVAL) &&
      (selectedQuoteDetails.assigned_to_id === userId ||
        _.keys(userOperations).includes('Deal Hub')) ? (
        <Box sx={[componentStyle.policyContainer, componentStyle.boxMargin]}>
          {/* {QuoteStatus.FORWARD_TO_DD === selectedQuoteDetails.status ? (
            <Button
              onClick={() => {
                setSelectedOption('quoteEscalated');
                escalateToManager(QuoteStatus.ESCALATE_FOR_APPROVAL);
              }}
              sx={[
                commonStyle.customButton('#4168E1', null, '4px', null),
                componentStyle.btnRight,
              ]}>
              {escalateLoader ? (
                <CircularProgress sx={componentStyle.loaderStyle} size={24} />
              ) : (
                intl.formatMessage({ id: 'escalateForApproval' })
              )}
            </Button>
          ) : null} */}
          {/* {QuoteStatus.ESCALATE_FOR_APPROVAL === selectedQuoteDetails.status ? (
            <Button
              onClick={() => {
                setSelectedOption('quoteApproved');
                escalateToManager(QuoteStatus.APPROVED);
              }}
              sx={[
                commonStyle.customButton('#4168E1', null, '4px', null),
                componentStyle.btnRight,
              ]}>
              {escalateLoader ? (
                <CircularProgress sx={componentStyle.loaderStyle} size={24} />
              ) : (
                intl.formatMessage({ id: 'approve' })
              )}
            </Button>
          ) : null} */}

          {/* <Button
            onClick={resendToAE}
            sx={[commonStyle.customButton('#865DDA', null, '4px', null), componentStyle.btnRight]}>
            {resendToAeLoader ? (
              <CircularProgress sx={componentStyle.loaderStyle} size={24} />
            ) : (
              intl.formatMessage({ id: 'resend_to_ae' })
            )}
          </Button> */}
          {/* <Box sx={componentStyle.settingsContainer}>
            <Box sx={componentStyle.iconStyle}>
              <SettingsIcon />
            </Box>
            <Button sx={componentStyle.settingsConfigText} onClick={() => openConfigPopup()}>
              {intl.formatMessage({ id: 'field_config' })}
            </Button>
          </Box> */}
        </Box>
      ) : null}
      {/* {
        selectedQuoteDetails.status === QuoteStatus.ESCALATE_FOR_APPROVAL
      } */}
      {/* {selectedQuoteDetails.status === QuoteStatus.DRAFT &&
      selectedQuoteDetails.owner_id === userId ? (
        <Box sx={[componentStyle.policyContainer, componentStyle.boxMargin]}>
          <Button
            onClick={() => {
              setSelectedOption('quoteEscalated');
              escalateToManager(QuoteStatus.ESCALATE_FOR_APPROVAL);
            }}
            sx={[
              commonStyle.customButton('#4168E1', null, '4px', '12px 0px'),
              componentStyle.btnRight,
            ]}>
            <ArrowUpwardIcon />
          </Button>
          <Button
            onClick={() => {
              setSelectedOption('quoteEscalated');
              if (isApprovalRequired) {
                escalateToManager(QuoteStatus.ESCALATE_FOR_APPROVAL);
              } else {
                selfApproval(QuoteStatus.APPROVED);
              }
            }}
            disabled={disableEscalation}
            sx={[commonStyle.customButton('#4168E1', null, '4px', null), componentStyle.btnRight]}>
            {escalateLoader ? (
              <CircularProgress sx={componentStyle.loaderStyle} size={24} />
            ) : (
              getButtonLabel()
            )}
          </Button>
          <Button
            onClick={forwardToDealDesk}
            sx={[
              commonStyle.customButton('#865DDA', null, '4px', '12px 0px'),
              componentStyle.btnRight,
            ]}>
            <ArrowForwardIcon />
          </Button>
          <Button
            onClick={forwardToDealDesk}
            sx={[commonStyle.customButton('#865DDA', null, '4px', null), componentStyle.btnRight]}>
            {forwardToDealDeskLoader ? (
              <CircularProgress sx={componentStyle.loaderStyle} size={24} />
            ) : (
              intl.formatMessage({ id: 'forwardDealDesk' })
            )}
          </Button>
        </Box>
      ) : null} */}
      {/* {selectedQuoteDetails.id && (
        <ConfigContract
          open={configPopup}
          onClose={() => setConfigPopup(false)}
          selectedQuote={selectedQuoteDetails}
          loader={contractSpecificLoader}
          selectedProductIds={selectedPricingProducts.map((product: any) => product.product_id)}
          updateContractSpecificData={updateContractSpecificData}
        />
      )} */}
      <NotesPopup
        loader={apiLoader}
        onSave={(value) => {
          if (value) {
            saveComments(value);
            return true;
          }
          setOpenNote(false);
          return null;
        }}
        open={openNote}
      />
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
      {showDeleteConfirmDialog ? (
        <ConfirmDialog
          dialogConfig={{
            name: 'confirmDelete',
            text: 'confirmMessageWithName',
            open: showDeleteConfirmDialog,
            productName: deleteProdName,
            loader: deleteLoader,
            handleConfirm: onDeleteProduct,
            handleCancel: () => {
              setDeleteProdId('');
              setDeleteProdName('');
              setShowDeleteConfirmDialog(false);
            },
          }}
        />
      ) : null}
    </Box>
  );
};

const HandleSelect = (props: any) => {
  const { children, callback, val } = props;
  useEffect(() => {
    if (callback && val !== 0 && val !== 1) {
      callback();
    }
  }, []);

  return children;
};

const HandleTextBox = (props: any) => {
  const { children, callback, val } = props;

  useEffect(() => {
    if (val === null) {
      callback();
    }
  }, []);

  return children;
};

export default injectIntl(RightPortion);
